.chatEmptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-size: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.chatEmptyTitle {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 27px;
}

.chatButtonsContainer {
    display: flex;
    flex-direction: row;
    margin: 20px;
}

.chatEmptyOr {
    margin: 0 18px;
    display: flex;
    align-items: center;
}

.chatEmptyCreateButton {
    width: 174px;
    height: 35px;
    font-size: 14px;
    border-radius: 17.5px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: #EF4639;
    color: #FFFFFF;
    cursor: pointer;

}