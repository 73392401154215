.Chat {
  .chatRow {
    outline: 1px solid #E7EAF1;
    height: calc(100vh - 64px);
    // min-height: 825px;

    .chatColSm3 {
      background-color: #fafafa;
      height: hw;
      padding-top: 30px;
      &.hideChatList {
        display: none;
      }
    }

    .chatColSm9 {
      outline: 1px solid #E7EAF1;
      height: hw;

      h4 {
        margin: 40% auto;
        text-align: center;
        font-weight: 100;
        font-size: 18px;
        color: #605655;
        font-family: Poppins;
      }
    }
  }
}

.ChatEngine {
  .content-part {
    margin-top: 64px !important;
  }
}



@media (min-width: 576px) and (max-width: 767.98px) {
  .Chat {
    .chatRow {
      .chatColSm3 {
        padding-top: 15px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .Chat {
    .chatRow {
      .chatColSm9 {
        outline: none;
      }
    }
  }
}
