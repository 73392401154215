.inputContainer {
  width: 100%;
  .searchIcon {
    position: absolute;
    padding: 10px 10px 10px 15px;
    color: #989DA1;
  }
  .searchInput {
    width: 100%;
    height: 35px;
    padding: 0 40px;
    background: #fff;
    border: 1px solid #e7eaf1;
    border-radius: 20.5px;
    &::placeholder {
      font-size: 14px;
      color: #989DA1;
      opacity: 0.5;
    }
  }


  .closeButtonContainer {
    position: absolute;
    top: 7px;
    right: 23px;
    width: 21px;
    height: 21px;
    background: #FAFAFA;
    border: 1px solid #E7EAF1;
    border-radius: 50%;
    visibility: hidden;

    &:hover {
      background: #E7EAF1;
    }
  }

  .closeButtonStickOne, .closeButtonStickTwo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 9px;
    background: #605655;
    transform-origin: 50% 50%;
  }

  .closeButtonStickOne {
    transform: translate(-50%, -50%) rotate(45deg)
  }

  .closeButtonStickTwo {
    transform: translate(-50%, -50%) rotate(-45deg)
  }
}