.container {
  button {
    width: 121px;
    height: 48px;
    background: #ececec;
    border-radius: 24px;
    border: 0;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #697578;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      cursor: auto;
    }
  }
  .phoneErr {
    margin-top: 5px;
    font-size: 12px;
    line-height: 16px;
    color: #ef4639;
  }
}

.titleContainer {
  width: 100%;
  // margin-top: 40px;
  display: inline-flex;
  align-items: baseline;
  span {
    flex: 1;
    font-size: 22px;
    color: #ef4639;
    font-weight: 600;
    i {
      cursor: pointer;
    }
  }
  h4 {
    flex: 2;
    font-size: 20px;
    line-height: 30px;
    color: #697578;
    font-weight: 400;
  }
}

.phoneContainer {
  margin-top: 20px;
  display: flex;
  div {
    flex: 2
  }
  button {
    margin-left: 10px;
    flex: 1;
    i {
      display: none;
    }
    &.active {
      background: #ef4639;
      color: #fff;
    }
    &.verified {
      background: #4d7e83;
      i {
        display: inline;
        font-size: 16px;
        color: #fff;
      }
      span {
        display: none;
      }
    }
    &:disabled {
      cursor: auto;
    }
  }
}

.emailContainer {
  margin-top: 20px;
  display: flex;
  div {
    flex: 2
  }
  button {
    margin-left: 10px;
    flex: 1;
    i {
      display: none;
    }
    &.active {
      background: #ef4639;
      color: #fff;
    }
    &.verified {
      background: #4d7e83;
      i {
        display: inline;
        font-size: 16px;
        color: #fff;
      }
      span {
        display: none;
      }
    }

    &:disabled {
      cursor: auto;
    }
  }
}

.submitContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    width: 105px;
    height: 48px;
    background: #ef4639;
    color: #fff;
    i {
      display: inline;
      margin-left: 10px;
    }
    &:disabled {
      color: #697578;
      background: #ececec;
      cursor: auto;
    }
  }
}
