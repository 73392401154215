.chatScreen {
  height: 100%;
  .chatInfoContainer {
    border-bottom: 1px solid #e7eaf1 ;
    padding: 25px 30px;
    height: 114px;
    display: flex;
    justify-content: space-between;
    .backToChatList {
      display: none;
    }
    .avatarNameContainer {
      display: flex;
      img {
        position: relative;
        margin-right: 17px;
        width: 64px;
        min-width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: lightgrey;
        border: 1px solid #b9b9b9;
        font-size: 0;
        overflow: hidden;
      }
      img::before{content:"";position:absolute;top:0;left:0;width:100%;height: 100%;background:url("/public/frontend/images/no-image.jpg")no-repeat, center;background-size: contain;}
      .nameContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .chatName {
          color: #000;
          font-size: 18px;
          // line-height: 27px;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .chatMember {
          color: #989DA1;
          font-size: 14px;
          line-height: 21px;
        }
      }
      .chatAvatarContainer {
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 65px;
        background-color: lightgray;
        border-radius: 50%;
        margin-right: 12px;
        i {
          font-size: 42px;
        }
      }
    }
    .chatMenu {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover > i {
        color: #697578;
      }

      i {
        font-size: 22px;
        color: #989DA1;
      }
      .openChatMenu {
        width: 130px;
        top: 55px;
        left: -19px;
      }
    }
  }
}


.sendMessagePanel {
  width: 100%;
  min-height: 75px;
  //max-height: 100px;
  position: relative;
  border-top: 1px solid #E7EAF1;
  bottom: 0;
  background-color: #fff;

  .selectedImagesSparks {
    max-width: 100%;
    margin: 5px 15px;
    overflow: auto;
    display: flex;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button,
    &::-webkit-resizer { 
     height:8px;
     width: 4px;
    } 

    div {
      display: inline-block;
      position: relative;

      img {
        width: 120px;
        height: 120px;
        margin: 15px;
        object-fit: cover;
        border: 1px solid grey;
      }

      i {
        position: absolute;
        right: 5px;
        color: #e74437;
        background: #fff;
        font-size: 20px;
        top: 7px;
        text-shadow: 0 0 black;
        border-radius: 50%;
        padding: 0;
        cursor: pointer;
      }
    }
  }

  .sendMessMain {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 17px 20px 17px 20px;
    .textAreaContainer {
      width: 100%;
      margin-right: 10px;
    }
    .sendMessInput {
      width: 100%;
      height: 36px;
      //min-height: 36px;
      //resize: vertical;
      //max-height: 58px;
      padding: 6px 10px;
      background-color: #F2F2F2;
      border: 1px solid #E7EAF1;
      resize: none;
      border-radius: 17.5px;
      color: #605655;
      font-size: 15px;
      line-height: 22px;
      outline: none;

      &::-webkit-scrollbar {
        width: 6px;
        height: 0;
      }

      &::placeholder {
        color: #989DA1;
        font-size: 15px;
        line-height: 22px;
      }
    }

    .erroredInput {
      border-color: red;
    }
    .twoLinedMessInput {
      height: 58px;
    }

    .charLimitError {
      font-size: 14px;
      line-height: 21px;
      color: red;
      position: absolute;
      bottom: 83px;
      left: 30px;
    }

    .submitContainer {
      display: flex;
      justify-content: flex-end;

      .emojiModalContainer {
        position: absolute;
        bottom: 92px;
        right: 18px;
        &::after {
          content: '';
          position: absolute;
          bottom: -5px;
          left: 50%;
          border: 6px solid transparent;
          border-bottom-color: #ffffff;
          border-right-color: #ffffff;

          transform: rotate(45deg);
        }
      }

      .addPhoto, .emojiButton {
        margin-right: 10px;
        label {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: #fff;
          i {
            color: #EF4639;
          }
        }
        .uploadPhotoInput {
          display: none;
        }

      }
      .addPhoto label:hover {
        background: #EF4639;
      }
      .addPhoto label:hover path {
        fill: #ffffff;
      }
      .addPhoto.disabled {
        label path {
          fill: #989DA1;
        }
        label:hover {
          background: none;
          cursor: default;
        }
      }

      .emojiButton {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #fff;
        transition: background 0.2s ease-out;

        &:hover {
          background-color: #EF4639;
        }

        &:hover > i {
          color: #FFFFFF;
        }

        i {
          font-size: 20px;
          color: #EF4639;
        }

        &.active {
          background: #EF4639;
          i {
            color: #FFFFFF;
          }
        }
      }

      .messSend {
        width: 105px;
        height: 35px;
        border: 0;
        border-radius: 20.5px;
        color: #fff;
        background-color: #EF4639;

        &:hover {
        background-color: #FF5B4F;
        }
        //i {
        //  //display: none;
        //}

        //&[disabled] {
        //
        //}
      }
    }
  }
}

.userChatContainer {
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  // max-height: 660px;
  // height: calc(100% - 114px - 178px);
  height: calc(100vh - 64px - 114px - 99px);
  overflow-anchor: auto;
  display: flex;
  flex-flow: column nowrap;
  user-select: text;

  // instead justify-content: flex-end - flex-end disable vertical scroll
  &::-webkit-scrollbar-button,
  .userChatContainer::-webkit-resizer
  { 
   height:0;
  } 

  & > :first-child {
    margin-top: auto !important;
  }

  &.loadedImg {
    height: calc(100vh - 64px - 97px - 250px);
  }

  .conversationDay {
    margin: 36px 0;
    display: flex;
    justify-content: center;
    font-size: 11px;
    line-height: 16px;
    color: #989DA1;
  }

  .unreadMessagesBelow {
    display: none;
    margin: 22px 28px 22px 0;
    justify-content: space-between;
    &::before {
      content: "";
      width: 40%;
      margin-bottom: 10px;
      border-bottom: 1px solid #EF4639;
    }
    &::after {
      content: "";
      width: 40%;
      margin-bottom: 10px;
      border-bottom: 1px solid #EF4639;
    }
    & > span {
      font-family: Poppins;
      font-size: 12px;
      line-height: 18px;
      padding: 0 11px;
      text-align: center;
      color: #EF4639;
    }
  }

  .showUnreadMessagesBelow {
    display: flex;
  }

  .hideConversationDate {
    display: none;
  }


  .messageImageBlock {
    min-height: 279px;
    min-width: 279px;
    div {
      div {
        div {
          margin: 5px 0px 2px 0px;
          img {
            padding: 7px;
            border: 1px solid #e7eaf1;
            border-radius: 25px 25px 7.5px 7.5px;
            display: inline-block;
            object-fit: contain;
            // width: 49%;

          }
        }
      }
    }
  }

  .chat {
    display: flex;
    margin-bottom: 2px;
    margin-top: 12px;

    .userMessageContainer {
      max-width: 70%;
      display: flex;
      .messageText {
        min-height: 35px;
        padding: 5px 20px 7px;
        border: 1px solid #e7eaf1;
        border-radius: 25px 25px 7.5px 7.5px;
        font-size: 15px;
        line-height: 22px;
        color: #605655;
        overflow-wrap: anywhere;
      }
    }

    .scrollBottom {
      position: absolute;
      right: 15px;
      bottom: 15px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #F2F2F2;
      border: 1px solid #E7EAF1;
      cursor: pointer;
      transition: all .350s linear;
  
      &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        left: 11px;
        top: 10px;
        transform: rotate(45deg);
        border-right: 2px solid #989DA1;
        border-bottom: 2px solid #989DA1;
      }
    }
  
    .scrollBottomAppear {
      bottom: 125px;
    }

    &.myMessage {
      margin-right: 25px;
      flex-direction: row-reverse;
      .userAvatarContainer {
        display: none;
      }
      
      .userMessageContainer {
        flex-direction: column;
        width: 100%;

        .messageText {
          border-bottom-left-radius: 25px;
          background: #e7eaf1;
          float: right;
          display: inline-block;
        }

        .messageImageBlock {
          div {
            div {
              div {
                justify-content: flex-end;
                img {
                  float: right;
                }
              }
            }
          }
        }
      }

      .displayTime {
        justify-content: flex-end;
        margin: 2px 0px 0px 0px;
      }
    }

    &.friendMessage {
      flex-direction: row;
      .userAvatarContainer {
        margin-right: 10px;
      }
      .userMessageContainer {
        flex-direction: column;
        width: 100%;
        .messageText {
          border-bottom-right-radius: 25px;
          display: inline-block;
          overflow-wrap: anywhere;
        }
      }
      .displayTime {
        margin: 2px 0px 0px 0px;
      }
    }

    .userAvatarContainer {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      img:not(.showSideAvatar) {
        display: none;
      }
    }

    .displayTime {
      min-width: 60px;
      display: flex;
      align-items: center;
      font-size: 11px;
      line-height: 16px;
      color: #989da1;
    }
  }

  .my + .my .messageText {
    border-top-right-radius: 7.5px;
  }

  .unread .messageText {
    font-weight: bold;
  }

  .his + .his .messageText {
    border-top-left-radius: 7.5px;
  }

  .showSideAvatar {
    position:relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 0;
    border: 1px solid #b9b9b9;
    overflow: hidden;
  }

  .showSideAvatar::before{content:"";position:absolute;top:0;left:0;width:100%;height: 100%;background:url("/public/frontend/images/no-image.jpg")no-repeat, center;background-size: contain;}
  
  .chatBubbleStop {
    .userMessageContainer {
      flex-direction: column !important;
      width: 100%;

      .messageText {
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
      }
    }
  }

  .chatBubbleStop.myMessage {
    .userMessageContainer {
      .displayTime {
        justify-content: flex-end;
        margin: 2px 0 0 0;
      }
    }
  }

  .chatBubbleStop.friendMessage {
    .userMessageContainer {
      .displayTime {
        justify-content: flex-start;
        margin: 2px 0 0 0;
      }
    }
  }

  .myMessage .displayTime {
    margin-right: 10px;
  }

  .friendMessage .displayTime {
    margin-left: 10px;
  }

  .noActiveChat {
    align-self: center;
  }
}

.leaveChatButton {
  color: #EF4639 !important;
}

@media (max-width: 575.98px) {
  .chatScreen {
    .chatInfoContainer {
      padding: 15px 20px;
      height: 98px;
      background: #fff;
      .backToChatList {
        margin-right: 20px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 24px;
          color: #989DA1;
        }
      }
      .avatarNameContainer {
        width: 100%;
        display: flex;
        align-items: center;
        img {
          margin-right: 17px;
          width: 48px;
          min-width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: lightgrey;
        }
        .nameContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .chatName {
            color: #000;
            font-size: 15px;
            // line-height: 27px;
            max-width: 200px;
          }
          .chatMember {
            color: #989DA1;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
      .chatMenu {
        .openChatMenu {
          width: 130px;
          top: 55px;
          left: -107px;
          &::before {
            left: 100px;
          }
        }
      }
    }
  }

  .sendMessagePanel {
    width: 100%;
    min-height: 75px;
    max-height: 100px;
    position: relative;
    border-top: 1px solid #E7EAF1;
    bottom: 0;
    background-color: #fff;
  
    .sendMessMain {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 17px 20px 17px 20px;
      .textAreaContainer {
        width: 100%;
        margin-right: 10px;
      }
      .sendMessInput {
        width: 100%;
        min-height: 36px;
        resize: vertical;
        max-height: 58px;
        padding: 6px 10px;
        background-color: #F2F2F2;
        border: 1px solid #E7EAF1;
        resize: none;
        border-radius: 17.5px;
        color: #605655;
        font-size: 15px;
        line-height: 22px;
  
        &::placeholder {
          color: #989DA1;
          font-size: 15px;
          line-height: 22px;
        }
      }
      .submitContainer {
        .messSend {
          padding-top: 3px;
          width: 35px;
          height: 35px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: transparent;
          font-size: 0;
          i {
            font-size: 14px;
            color: #fff;
            display: initial;
          }
        }

        .emojiModalContainer {
          &::after {
            left: 75%;
          }
        }
      }
    }
  }
  
  .userChatContainer {
    padding: 10px;
    height: calc(100vh - 64px - 97px - 99px);
    // &.loadedImg {
    //   height: calc(100vh - 64px - 97px - 230px);
    // }
    .chat {
      .userMessageContainer {
        .messageText {
          padding: 5px 15px 7px;
        }
      }
      &.myMessage {
        .userAvatarContainer {
          margin-left: 5px;
        }
      }
    }
    .myMessage .displayTime {
      margin-right: 5px;
    }
  }
}

.hiddenBlock {
  opacity: 1;
}
