.formLabelGroup {
    position: relative;
    margin-bottom: 20px;
}

.lessBottomMargin {
    margin-bottom: 0.5rem;
    margin-top: 16px;
}

.passwordContainer {
    position: relative;
}

.reactTooltip {
    width: 240px;
    opacity: 0.75!important;
    border-radius: 5px!important;
    font-size: 12px!important;
    line-height: 16px!important;
}

.input {
    width: 100%;
    padding: var(--input-padding-y) var(--input-padding-x);
    padding-left: 20px;
    border-radius: 24px;
    height: 48px;
    font-size: 14px;
    line-height: 21px;
    color: #495057;
    font-weight: 300;
    border: 1px solid #ececec;
    background-color: #fcfcfc;
}

.passwordInput {
    padding-right: 35px;
}

.input.withError {
    border: 1px solid #EF4639;
}

.inputLabel {
    padding: var(--input-padding-y) var(--input-padding-x);
    padding-left: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    font-size: 14px;
    width: auto;
    margin-bottom: 0;
    line-height: 25px;
    color: #999999;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
    cursor: text;
}

.fieldIcon {
    float: right;
    position: relative;
    z-index: 2;
    cursor: pointer;
    font-size: 20px;
    line-height: 21px;
}

/* .form-label-group > label { width: auto; right: 0; } */

.input::-webkit-input-placeholder {
    color: transparent;
}

.input:-ms-input-placeholder {
    color: transparent;
}

.input::-ms-input-placeholder {
    color: transparent;
}

.input::-moz-placeholder {
    color: transparent;
}

.input::placeholder {
    color: transparent;
}

.input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
}

.input:not(:placeholder-shown) ~ .inputLabel:not(.showHidePass) {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 10px;
    line-height: 15px;
    color: #999999;
}

/*input:-webkit-autofill:hover, */
input:-webkit-autofill:focus + .input:not(:placeholder-shown) ~ .inputLabel:not(.showHidePass){
    font-size: 10px;
}

.inputError {
    font-size: 12px;
    line-height: 16px;
    color: #EF4639;
    margin-bottom: 16px;
}
