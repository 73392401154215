.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.6);

  .modalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    z-index: 1055;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    outline: none;

    .modalDialog {
      position: relative;
      width: auto;
      min-width: 570px;
      max-width: 600px;
      z-index: 1000;
      max-height: 849px;
      pointer-events: none;

      .modalContent {
        position: relative;
        pointer-events: auto;
        width: 100%;
        height: 100%;
        background: #fff;
        border: 0;

        .modalChatHeader {
          position: relative;
          display: flex;
          justify-content: center;
          height: 68px;
          background-color: #ef4639;

          .modalTitle {
            display: flex;
            align-items: center;
            font-size: 23px;
            color: #fff;
          }

          i {
            cursor: pointer;
            position: absolute;
            font-size: 20px;
            color: #fff;
            top: 24px;
            left: 24px;
          }
          
          .createChat {
            width: 105px;
            height: 36px;
            position: absolute;
            font-size: 14px;
            line-height: 21px;
            border: 0;
            border-radius: 20.5px;
            color: #EF4639;
            background-color: #fff;
            top: 16px;
            right: 16px;
          }
        }

        .singleChatBody {
          margin: 0 30px;
          padding: 50px 0;
          min-height: 173px;
          max-height: 458px;
          display: flex;
          justify-content: center;
          flex-direction: column;

          label {
            margin-bottom: 3px;
          }

          .inputContainer {
            position: relative;
            width: 100%;

            .searchIcon {
              position: absolute;
              padding: 13.72px 12.72px;
              color: #E7EAF1;;
            }

            .searchUserInput {
              //text-indent: 41px;
            }
          }

          &.changePadding {

            padding-bottom: 35px;
          }

        }

        .groupChatBody {
          padding: 15px 0;
          min-height: 458px;
          max-height: 781px;
          width: 600px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          & > div:not(.selectedMembersContainer) {
            margin-left: 30px;
            margin-right: 30px;
          }
          .titleAndPhotoContainer {
            display: flex;
            justify-content: space-between;

            .titleContainer {
              width: 50%;
              align-self: flex-end;

              label {
                margin-bottom: 3px;
              }
            }

            .uploadPhotoContainer {
              display: grid;
              width: 50%;
              grid-template-columns: 50% 50%;
              justify-items: center;
              label:first-child {
                margin-top: 17px;
                cursor: pointer;
                justify-self: end;
              }
              .uploadAvatarArea {
                margin-bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #b9b9b9;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                background: transparent;
                cursor: pointer;
                overflow: hidden;

                i {
                  font-size: 30px;
                  color: #E7EAF1;
                }
                img {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
              .uploadPhotoInput {
                display: none;
              }
            }
          }

          .descriptionContainer {
            margin-top: 30px;
            @media (max-height: 1080px) {
              margin-top: 10px;
            }
            label {
              margin-bottom: 3px;
            }
          }

          .findUsersContainer {
            position: relative;
            margin-top: 30px;
            @media (max-height: 1080px) {
              margin-top: 10px;
            }
            label {
              margin-bottom: 3px;
            }
            .inputContainer {
              position: relative;

              .searchIcon {
                position: absolute;
                padding: 13.72px 12.72px;
                color: #E7EAF1;;
              }

              .searchUserInput {
                //text-indent: 41px;
              }

              .clearInputButton {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 43px;
                width: 43px;
                font-size: 18px;
                font-weight: 100;
                position: absolute;
                right: 0;
                top: 0;
                visibility: hidden;
              }

              .clearInputButton.visible {
                visibility: visible;
              }
            }
          }

          .selectedMembersContainer {
            margin-top: 20px;
            height: 127px;
            width: 100%;
            background-color: #E7EAF1;

            p {
              padding: 5px 30px;
              width: 100%;
              height: 30px;
              background-color: #fff;
              display: flex;
              justify-content: space-between;

              span {
                font-size: 14px;
                line-height: 21px;
                color: #989da1;
              }

              button {
                border: 0;
                font-size: 14px;
                line-height: 21px;
                color: #ef4639;
                background-color: #fff;
              }
            }
            .overflowUsers {
              margin: 0 30px;
              display: flex;
              overflow: auto;
              overflow-y: hidden;
              scrollbar-width: thin;
              &::-webkit-scrollbar { 
                width: 0px;
                height: 8px;
              }
              &::-webkit-scrollbar-button {
                width: 0px;
                height:8px;
              } 
              &::-webkit-resizer { 
                width: 0px;
                height:8px
              } 
              .selectedUserContainer {
                margin: 7px 12px 7px 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding-right: 8px;
                padding-top: 6px;
                position: relative;
                height: 100%;
                width: 68px;
                &:only-child {
                  margin-right: 0;
                }
                .userImageContainer {
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                  img {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                  }
                }
                .selectedUserName {
                  width: 60px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: center;
                  margin-top: 5px;
                  font-size: 12px;
                  line-height: 18px;
                  color: #605655;
                }
                .deleteFromSelectedButton {
                  position: absolute;
                  top: 0;
                  right: 0;
                  color: #fff;
                  width: 27px;
                  height: 27px;
                  border: 2px solid #fff;
                  border-radius: 50%;
                  background-color: #ef4639;
                }
              }
            }
          }

          .selectedMembers {
            margin-top: 10px;
            font-size: 14px;
            line-height: 21px;
            color: #989da1;
          }

          .createGroupSubmitContainer {
            margin-top: 24px;
            display: flex;
            justify-content: flex-end;

            .submit {
              width: 105px;
              height: 35px;
              background-color: #ef4639;
              border: 0;
              border-radius: 20.5px;
              color: #fff;
              font-size: 14px;
            }
          }
        }

        .usersBlockTitle,
        .usersBlockErrorTitle {
          margin-top: 10px;
          font-size: 14px;
          line-height: 21px;
          color: #989da1;
        }

        .usersBlockErrorTitle,
        .usersBlockErrorImg {
          color: red;
          font-size: 16px;
        }

        .usersBlockErrorImg {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        
        .userListContainer {
          margin-top: 5px;
          padding: 10px 0;
          // width: 100%;
          max-height: 295px;
          overflow-x: hidden;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 6px;
          }

          @media (max-height: 1080px) {
            max-height: 200px;
          }
          @media (max-height: 575px) {
            max-height: 155px;
          }

          .userContainer {
            cursor: pointer;
            padding: 10px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e7eaf1;
            &:hover {
              background: #E7EAF1;
            }
            &:only-child {
              margin-bottom: 0;
              padding-bottom: 0;
              border-bottom: none;
            }
            .userImage {
              margin-right: 10px;
              width: 35px;
              min-width: 35px;
              height: 35px;
              border-radius: 50%;
              background: transparent;
              transition: background 0.3s ease-out;
              border: 1px solid #b9b9b9;
              overflow: hidden;
              img {
                position: relative;
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                opacity: 1;
                transition: opacity 0.3s ease-out;
                font-size: 0;
                overflow: hidden;
              }
              img::before {
                content:"";
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height: 100%;
                background:url("/frontend/images/no-image.jpg")no-repeat, center;
                background-size: contain;
              }
              i {
                display: none;
                opacity: 0;
              }
              &.selectedUser {
                background: #EF4639;
                transition: background 0.3s ease-in;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 0;
                  height: 0;
                  opacity: 0;
                }
                i {
                  font-size: 18px;
                  opacity: 1;
                  color: #fff;
                  display: initial;
                  transition: opacity 0.3s ease;
                }
              }
            }

            .userNameContainer {
              display: flex;
              flex-direction: column;
              h3 {
                font-size: 18px;
                line-height: 27px;
                color: #605655;
              }
              span {
                font-size: 14px;
                line-height: 21px;
                color: #989da1;
              }
            }
          }
        }
      }
    }
  }
}

.searchUserInput {
  width: 100%;
  height: 43px;
  padding: 0 35px;
  //text-indent: 10px;
  background: #fff;
  border: 1px solid #e7eaf1;
  border-radius: 3px;
  &::placeholder {
    font-size: 14px;
    color: #989DA1;
    opacity: 0.5;
  }
}

label {
  font-size: 18px;
  line-height: 27px;
  color: #605655;
}

.closeButtonContainer {
  position: absolute;
  top: 50%;
  right: 8px;
  width: 21px;
  height: 21px;
  background: #FAFAFA;
  border: 1px solid #E7EAF1;
  border-radius: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: pointer;
  &:hover {
    background: #E7EAF1;
  }
}

.closeButtonStickOne, .closeButtonStickTwo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 9px;
  background: #605655;
  transform-origin: 50% 50%;
}

.closeButtonStickOne {
  transform: translate(-50%, -50%) rotate(45deg)
}

.closeButtonStickTwo {
  transform: translate(-50%, -50%) rotate(-45deg)
}

@media (max-width: 575.98px) {
  .overlay {
    .modalContainer {
      overflow: unset;
      left:unset;
      bottom: unset;

      .modalDialog {
        min-width: 375px;
        max-height: 790px;
        .modalContent {
          .modalChatHeader {
            height: 58px;
            justify-content: flex-start;
            .modalTitle {
              margin-left: 70px;
              font-size: 19px;
            }
            i {
              top: 18px;
              left: 24px;
            }
            .createChat {
              width: 95px;
              top: 11px;
              right: 11px;
            }
          }
          .groupChatBody {
            padding: 10px 0 10px 0;
            width: 100%;
          }
          
          .groupChatBody {
            min-height: 400px;
            margin: 0 10px;
            & > div:not(.selectedMembersContainer) {
              margin-left: 10px;
              margin-right: 10px;
            }
            .titleAndPhotoContainer {
              .titleContainer {
                width: 60%;
              }
              .uploadPhotoContainer {
                label:first-child {
                  display: none;
                }
                .uploadAvatarArea {
                  width: 80px;
                  height: 80px;
                  border-radius: 50%;
                  background: transparent;
                  i {
                    font-size: 30px;
                    color: #E7EAF1;
                  }
                  img {
                    width: 80px;
                    height: 80px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
