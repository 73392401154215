.tabListItem {
  padding-top: 5px;
  padding-bottom: 5px;
  flex: 1;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  color: #605655;
  border-bottom: 1px solid #e7eaf1;
  transition: background-color 0.5s ease;
  cursor: pointer;

  &.tabListActive {
    color: #ef4639;
    border-bottom: 1px solid #ef4639;
  }
  &:not(.tabListActive):hover {
    background-color: #E7EAF1;
  }
}
