.input {
  width: 200px;
}

.titleContainer {
  width: 100%;
  // margin-top: 35px;
  display: inline-flex;
  align-items: baseline;
  span {
    flex-basis: 22%;
    font-size: 22px;
    color: #ef4639;
    font-weight: 600;
    i {
      cursor: pointer;
    }
  }
  h4 {
    font-size: 20px;
    line-height: 30px;
    color: #697578;
    font-weight: 500;
  }
}

.nameContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.usernameContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  img {
    margin: 20px 0 0 20px;
    width: 16px;
    height: 16px;
  }
  .userAvailable {
    word-break: break-word;
    margin: 10px 0 0 20px;
    font-size: 12px;
    line-height: 16px;
    color: #1db61a;
  }
  .userNotAvailable {
    margin: 10px 0 0 20px;
    font-size: 12px;
    line-height: 16px;
    color: #ef4639;
  }
}

.passwordContainer {
  display: flex;
  justify-content: space-between;
}

.policyContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  input {
    width: 20px;
    height: 20px;
    border: 1px solid #ececec;
    border-radius: 5px;
    background-color: #fcfcfc;
  }

  label {
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 16px;
    color: #697578;
    a {
      padding: 0 2px;
      color: #ef4639;
    }
  }
}

.submitContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    width:105px;
    height:48px;
    background:#ef4639;
    border-radius:24px;
    border:0;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    &[disabled] {
      background-color: #ececec;
      color: #697578;
    }
  }
  i {
    margin-left: 11px;
    font-size: 16px;
  }
}

.errAcceptTerms {
  margin-top: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #ef4639;
}

@media only screen and (max-width: 1200px) {
  .nameContainer,
  .passwordContainer {
    flex-direction: column;
  }

  .passwordContainer {
    margin-top: 0px;
  }

  .usernameContainer {
    margin-top: 0px;
    flex-direction: column;
    align-items: normal;

    .userAvailable,
    .userNotAvailable {
      margin: 0px 0px 16px 0px;
      br {
        display: none;
      }
    }
  }

  .input {
    width: 100%;
  }

  .policyContainer {
    flex-flow: wrap;

    .errAcceptTerms {
      margin-left: 0px;
      margin-top: 10px;
      width: 100%;
    }
  }
}