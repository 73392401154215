.container {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    width: 100%;
    // margin-top: 40px;
    display: inline-flex;
    align-items: baseline;
    span {
      //flex: 1;
      font-size: 22px;
      color: #ef4639;
      font-weight: 600;
      i {
        cursor: pointer;
      }
    }
    h4 {
      flex: 2;
      font-size: 20px;
      line-height: 30px;
      color: #697578;
      font-weight: 500;
      text-align: center;
    }
  }
  .planDescription {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    width: 194px;
    height: 28px;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    color: #697578;
    font-weight: 500;
  }
}

.plansContainer {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.cancelInfo {
  margin-top: 20px;
  text-align: center;
  font-size: 11px;
  line-height: 14px;
  color: #697578;
  font-weight: 500;
}

@media only screen and (max-width: 480px) {
  .plansContainer {
    justify-content: space-around;
  }
}
