.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100vh;
  width: 100%;
  z-index: 1051;
  overflow: auto;

  .container {
    margin: 15px;
    position: relative;
    top: 15%;
    padding: 45px 75px 64px 75px;
    max-width: 500px;
    max-height: 513px;
    background-color: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .closeReferral {
      position: absolute;
      padding: 7px;
      top: 23px;
      right: 23px;
      background-color: transparent;
      border: 0;

      i {
        font-size: 17px;
        color: #697578;
      }
    }

    .referralLogo {
      width: 110px;
      height: 110px;
      margin-bottom: 34px;
    }

    .referralInfo {
      color: #605655;
      line-height: 22.5px;
      text-align: center;
      margin-bottom: 24px;

      b.count {
        font-weight: 600;
      }

      b.referralBenefit {
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        color: #EF4639;
      }

      .iTunesCancelationTip {
        margin-top: 10px;
        font-family: Poppins;
        font-size: 15px;
        font-style: italic;
        text-decoration: underline;
        color: #928e8e;
      }
    }

    .referralModalActions {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .copyLink {
        background-color: #EF4639;
        color: #fff;
        margin-bottom: 30px;
        font-family: Poppins;
        font-size: 14px;
        &:hover {
          background: #FF5B4F;
        }
        &:active {
          background: #D83B2F;
        }

        .linkIcon {
          margin-right: 10px;
        }
      }

      .referralTextInput {
        position: absolute;
        opacity: 0;
        left: -1000px;
        top: -1000px;
      }

      .dontShowBlock {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .checkBoxWrapper {
          display: flex;
          align-items: center;

          span.text {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #605655;
          }
          .dontShowBox {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }

      .closeDetatilsBlock {
        display: flex;
        justify-content: space-between;

        .close {
          width: 40%;
          background-color: #fff;
          color: #EF4639;
          font-family: Poppins;
          font-size: 14px;
          border: 1px solid #F5F5F5;
          &:hover {
            background: #F5F5F5;
          }
          &:active {
            background: #EBEBEB;
          }
        }

        .details {
          width: 40%;
          background-color: #F5F5F5;
          color: #EF4639;
          font-family: Poppins;
          font-size: 14px;
          &:hover,
          &:active {
            background: #EBEBEB;
          }
        }
      }

      .closeDetatilsBlock:only-child {
        justify-content: center;
      }
    }
  }
}

.referralButton {
  width: 100%;
  height: 48px;
  border: 0;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media (max-width: 575.98px) {
  .modal {
    .container {
      padding: 45px 30px;
    }
  }
}

@media (max-width: 480px) {
  .modal {
    .container {
      padding: 25px 20px;

      .closeReferral {
        top: 15px;
        right: 15px;
  
        i {
          font-size: 18px;
        }
      }

      .referralLogo {
        width: 80px;
        height: 80px;
        margin-bottom: 15px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .referralInfo {
        font-size: 14px;
        line-height: 16.5px;
  
        b.referralBenefit {
          font-size: 17px;
          line-height: 24px;
        }
      }
    }
  }
}