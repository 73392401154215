.loginContainer {
  padding: 20px 0px 0px 0px;

  .loginChooseBlock {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    color: #697578;

    h4 {
      text-align: center;
      font-size: 20px;
      color: #697578;
      text-transform: uppercase;
      font-weight: 400;
      display: inline-block;
      margin-right: 6px;
    }

    a {
      color: #ee4639;
      margin: 0 0 0 6px;
      text-decoration: underline;
      font-size: 20px;
    }
  }

  .formSignin {
    width: 100%;
    max-width: 420px;
    margin: auto;
    padding: 0px 10px;
  }

  .singinForgotpassBlock {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .forgotPass a {
      color: #ee4639;
      font-size: 16px;
      line-height: 24px;
    }

    button {
      width: 127px;
      height: 48px;
      background: #ef4639;
      border-radius: 24px;
      border: 0;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #4d7e83;
        transition: 0.4s;
      }

      i {
        font-size: 18px;
        margin-left: 11px;
        color: #fff;
        line-height: 21px;
      }
    }
  }

  .marketBlock {
    display: flex;
    justify-content: space-between;
    margin: 130px 0px 0px 0px;
  }

}

.resetPadding {
  h2 {
    height: 240px;
  }
}

.loginSplText {
  display: block;
  text-align: center;
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  padding: 17px 0 0 0;
}

.loginRightInn {
  height: 340px;

  h4 {
    font-size: 20px;
    color: #697578;
    margin-bottom: 40px;
    text-align: center;
  }
  
  button {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: #ef4639;
    border: 0;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;

    &:hover {
      background: #4d7e83;
      transition: 0.4s;
    }

    i {
      font-size: 40px;
    }
  }
}


.dontHaveAccount {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  span {
    display: block;
    width: 100%;
    border-top: 1px solid #f2f2f2;
    text-align: center;
    color: #e74437;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding: 17px 0 0 0;
  }

  p {
    text-align: center;
    font-size: 16px;
    color: #a6a6a6;
    font-weight: 400;
    padding: 10px 0;

    a {
      color: #ee4639;
      text-decoration: underline
    }
  }
}

@media only screen and (max-width: 1920px) {}
@media only screen and (max-width: 1440px) {}
@media only screen and (max-width: 1376px) {}
@media only screen and (max-width: 1200px) {
  .loginRightBarLogo {
    display: none;
  }

  .welcomeLayoutLeftBar {
    display: none;
  }

  h4 {
    margin: 20px 0px 40px 0px;
  }
}
@media only screen and (max-width: 1020px) {}
@media only screen and (max-width: 768px) {
  .loginContainer .loginChooseBlock {
    margin-top: 20px;
    margin-bottom: 25px;
  }

  .loginContainer {
    .singinForgotpassBlock {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 130px;

      button, p {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
      }
    }

    .marketBlock {
      margin-top: 20px;
      justify-content: space-around;

      a img {
        width: 180px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .loginContainer .loginChooseBlock {
    margin-top: 20px;
    margin-bottom: 25px;
  }

  .loginContainer {
    .singinForgotpassBlock {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 130px;

      button, p {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
      }
    }

    .marketBlock {
      margin-top: 20px;
      justify-content: space-around;

      a img {
        width: 160px;
      }
    }
  }
}
@media only screen and (max-width: 375px) {
  .loginContainer .marketBlock {
    a img {
      width: 140px;
    }
  }
}

// 1920 1440 1376 1020 768 480 375