.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    .title {
      width: 100%;
      // margin-top: 40px;
      display: inline-flex;
      align-items: center;
      span {
        font-size: 22px;
        color: #ef4639;
        font-weight: 600;
        i {
          cursor: pointer;
        }
      }
      h4 {
        flex: 2;
        font-size: 20px;
        line-height: 30px;
        color: #697578;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase
      }
    }
  }

.renewCardContainer {
    margin-top: 20px;
    // margin-left: -3%;
    width: 100%;
}

.renewButton {
  width: 105px;
  height: 48px;
  background: #EF4639;
  border-radius: 24px;
  color: white;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: none;

  &:disabled {
    color: #697578;
    background: #ececec;
    cursor: auto;
  }

  span {
      font-size: 14px;
      margin-right: 8px;
      font-weight: 500;
    }
}