.planRenewCardContainer {
    display: inline-flex;
    justify-content: space-between;
    width: 106%;
    align-content: center;
    margin-left: -3%;
    margin-top: 20px;
}

.planRenewTitie {
    padding-top: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #697578;
}

.planRenewInfo {
    margin-top: 40px;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    color: #697578;
    margin-bottom: 10px;
}

.planRenewInfoTwo {
    margin-top: 30px;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    color: #697578;
    margin-bottom: 10px;
}