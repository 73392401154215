@media only screen and (max-width: 1200px) {
  .welcomeLayoutLeftBar {
    display: none;
  }
}

.loginRightInn {
  height: 240px;

  h4 {
    font-size: 20px;
    color: #697578;
    margin-bottom: 40px;
    text-align: center;
  }

  h2 {
    color: #697578;
    text-align: center;
    font-size: 16px;
    margin-bottom: 40px;
    line-height: 30px;

    span {
      display: block;
      font-weight: 400;
      padding: 17px 0px 0px 0px;
    }
  }

  button {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: #ef4639;
    border: 0;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;

    &:hover {
      background: #4d7e83;
      transition: 0.4s;
    }

    i {
      font-size: 40px;
    }
  }
}

.dontHaveAccount {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  span {
    display: block;
    width: 100%;
    border-top: 1px solid #f2f2f2;
    text-align: center;
    color: #e74437;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding: 17px 0 0 0;
  }

  p {
    text-align: center;
    font-size: 16px;
    color: #a6a6a6;
    font-weight: 400;
    padding: 10px 0;

    a {
      color: #ee4639;
      text-decoration: underline
    }
  }
}
