.style_formLabelGroup__3lycc {
    position: relative;
    margin-bottom: 20px;
}

.style_lessBottomMargin__25oab {
    margin-bottom: 0.5rem;
    margin-top: 16px;
}

.style_passwordContainer__2IeRe {
    position: relative;
}

.style_reactTooltip__nlcAp {
    width: 240px;
    opacity: 0.75!important;
    border-radius: 5px!important;
    font-size: 12px!important;
    line-height: 16px!important;
}

.style_input__3nV-h {
    width: 100%;
    padding: var(--input-padding-y) var(--input-padding-x);
    padding-left: 20px;
    border-radius: 24px;
    height: 48px;
    font-size: 14px;
    line-height: 21px;
    color: #495057;
    font-weight: 300;
    border: 1px solid #ececec;
    background-color: #fcfcfc;
}

.style_passwordInput__3jIjW {
    padding-right: 35px;
}

.style_input__3nV-h.style_withError__Uchai {
    border: 1px solid #EF4639;
}

.style_inputLabel__2X2Jk {
    padding: var(--input-padding-y) var(--input-padding-x);
    padding-left: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    font-size: 14px;
    width: auto;
    margin-bottom: 0;
    line-height: 25px;
    color: #999999;
    border: 1px solid transparent;
    border-radius: .25rem;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
    cursor: text;
}

.style_fieldIcon__3ykbf {
    float: right;
    position: relative;
    z-index: 2;
    cursor: pointer;
    font-size: 20px;
    line-height: 21px;
}

/* .form-label-group > label { width: auto; right: 0; } */

.style_input__3nV-h::-webkit-input-placeholder {
    color: transparent;
}

.style_input__3nV-h::-ms-input-placeholder {
    color: transparent;
}

.style_input__3nV-h::-moz-placeholder {
    color: transparent;
}

.style_input__3nV-h::placeholder {
    color: transparent;
}

.style_input__3nV-h:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
}

.style_input__3nV-h:not(:placeholder-shown) ~ .style_inputLabel__2X2Jk:not(.style_showHidePass__2bitA) {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 10px;
    line-height: 15px;
    color: #999999;
}

/*input:-webkit-autofill:hover, */
input:-webkit-autofill:focus + .style_input__3nV-h:not(:placeholder-shown) ~ .style_inputLabel__2X2Jk:not(.style_showHidePass__2bitA){
    font-size: 10px;
}

.style_inputError__26IDA {
    font-size: 12px;
    line-height: 16px;
    color: #EF4639;
    margin-bottom: 16px;
}

.modal_rootModalStyle__1hJen {
  width: 200px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.2); }

.referral_modal__10eqI {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100vh;
  width: 100%;
  z-index: 1051;
  overflow: auto; }
  .referral_modal__10eqI .referral_container__14FzJ {
    margin: 15px;
    position: relative;
    top: 15%;
    padding: 45px 75px 64px 75px;
    max-width: 500px;
    max-height: 513px;
    background-color: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
    .referral_modal__10eqI .referral_container__14FzJ .referral_closeReferral__2cNk9 {
      position: absolute;
      padding: 7px;
      top: 23px;
      right: 23px;
      background-color: transparent;
      border: 0; }
      .referral_modal__10eqI .referral_container__14FzJ .referral_closeReferral__2cNk9 i {
        font-size: 17px;
        color: #697578; }
    .referral_modal__10eqI .referral_container__14FzJ .referral_referralLogo__UL_rt {
      width: 110px;
      height: 110px;
      margin-bottom: 34px; }
    .referral_modal__10eqI .referral_container__14FzJ .referral_referralInfo__1W0z0 {
      color: #605655;
      line-height: 22.5px;
      text-align: center;
      margin-bottom: 24px; }
      .referral_modal__10eqI .referral_container__14FzJ .referral_referralInfo__1W0z0 b.referral_count__2ryhn {
        font-weight: 600; }
      .referral_modal__10eqI .referral_container__14FzJ .referral_referralInfo__1W0z0 b.referral_referralBenefit__3_691 {
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        color: #EF4639; }
      .referral_modal__10eqI .referral_container__14FzJ .referral_referralInfo__1W0z0 .referral_iTunesCancelationTip__2KSlL {
        margin-top: 10px;
        font-family: Poppins;
        font-size: 15px;
        font-style: italic;
        text-decoration: underline;
        color: #928e8e; }
    .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_copyLink__mJSN6 {
        background-color: #EF4639;
        color: #fff;
        margin-bottom: 30px;
        font-family: Poppins;
        font-size: 14px; }
        .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_copyLink__mJSN6:hover {
          background: #FF5B4F; }
        .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_copyLink__mJSN6:active {
          background: #D83B2F; }
        .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_copyLink__mJSN6 .referral_linkIcon__1CJCb {
          margin-right: 10px; }
      .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_referralTextInput__lkZQ1 {
        position: absolute;
        opacity: 0;
        left: -1000px;
        top: -1000px; }
      .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_dontShowBlock__1pFvg {
        display: flex;
        justify-content: center;
        margin-bottom: 30px; }
        .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_dontShowBlock__1pFvg .referral_checkBoxWrapper__1I1D_ {
          display: flex;
          align-items: center; }
          .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_dontShowBlock__1pFvg .referral_checkBoxWrapper__1I1D_ span.referral_text__5T-oU {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #605655; }
          .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_dontShowBlock__1pFvg .referral_checkBoxWrapper__1I1D_ .referral_dontShowBox__1biRP {
            width: 20px;
            height: 20px;
            margin-right: 10px; }
      .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_closeDetatilsBlock__hZsjw {
        display: flex;
        justify-content: space-between; }
        .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_closeDetatilsBlock__hZsjw .referral_close__1FSrH {
          width: 40%;
          background-color: #fff;
          color: #EF4639;
          font-family: Poppins;
          font-size: 14px;
          border: 1px solid #F5F5F5; }
          .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_closeDetatilsBlock__hZsjw .referral_close__1FSrH:hover {
            background: #F5F5F5; }
          .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_closeDetatilsBlock__hZsjw .referral_close__1FSrH:active {
            background: #EBEBEB; }
        .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_closeDetatilsBlock__hZsjw .referral_details__2Zpa- {
          width: 40%;
          background-color: #F5F5F5;
          color: #EF4639;
          font-family: Poppins;
          font-size: 14px; }
          .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_closeDetatilsBlock__hZsjw .referral_details__2Zpa-:hover, .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_closeDetatilsBlock__hZsjw .referral_details__2Zpa-:active {
            background: #EBEBEB; }
      .referral_modal__10eqI .referral_container__14FzJ .referral_referralModalActions__1nnOp .referral_closeDetatilsBlock__hZsjw:only-child {
        justify-content: center; }

.referral_referralButton__2ehNa {
  width: 100%;
  height: 48px;
  border: 0;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }

@media (max-width: 575.98px) {
  .referral_modal__10eqI .referral_container__14FzJ {
    padding: 45px 30px; } }

@media (max-width: 480px) {
  .referral_modal__10eqI .referral_container__14FzJ {
    padding: 25px 20px; }
    .referral_modal__10eqI .referral_container__14FzJ .referral_closeReferral__2cNk9 {
      top: 15px;
      right: 15px; }
      .referral_modal__10eqI .referral_container__14FzJ .referral_closeReferral__2cNk9 i {
        font-size: 18px; }
    .referral_modal__10eqI .referral_container__14FzJ .referral_referralLogo__UL_rt {
      width: 80px;
      height: 80px;
      margin-bottom: 15px; }
      .referral_modal__10eqI .referral_container__14FzJ .referral_referralLogo__UL_rt img {
        width: 100%;
        height: 100%; }
    .referral_modal__10eqI .referral_container__14FzJ .referral_referralInfo__1W0z0 {
      font-size: 14px;
      line-height: 16.5px; }
      .referral_modal__10eqI .referral_container__14FzJ .referral_referralInfo__1W0z0 b.referral_referralBenefit__3_691 {
        font-size: 17px;
        line-height: 24px; } }

.styles_welcomeLayout__XELy6 {
  overflow: auto;
  height: 100vh;
  margin-right: 5px; }

.styles_bg__3B4du img {
  position: fixed;
  right: 0;
  height: 100%; }

.styles_container__2XiS4 {
  min-height: 602px;
  max-width: 1140px; }

.styles_layout__28IdQ {
  background: #fff;
  box-shadow: 0px 0px 12px #A1A1A1;
  position: relative;
  top: 38px;
  border-radius: 10px;
  overflow: hidden;
  min-height: 350px; }

.styles_abortRegistration__2e3J5 {
  position: absolute;
  right: 25px;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: 0; }
  .styles_abortRegistration__2e3J5 i {
    font-size: 18px;
    color: #697578; }

.styles_leftBar__Zynm8 {
  text-align: center;
  height: 100%;
  padding: 50px 50px 20px 50px;
  background: #ef4639 url("/frontend/images/inner-bg.png");
  background-size: cover; }

.styles_resetPadding__2WRKm {
  padding: 0; }

.styles_logo__OyTk1 {
  text-align: center;
  margin-bottom: 30px; }
  .styles_logo__OyTk1 img {
    width: 152px;
    height: 57px; }

.styles_logoText__T53Bk {
  display: block;
  text-align: center;
  color: #4d7e83;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding-top: 3px;
  margin-bottom: 20px; }

.styles_topBlock__1yFQo {
  text-align: left; }

.styles_mainLogo__3IPOx {
  width: 135px;
  height: 51px; }

.styles_tagLine__28jYD {
  font-size: 14px;
  display: inline-block;
  padding: 0 0 0 50px;
  line-height: 21px;
  font-weight: 300;
  vertical-align: bottom;
  text-align: left;
  color: #fff; }

.styles_welcome__37SHF {
  font-size: 72px;
  line-height: 64px;
  text-align: left;
  margin-top: 122px;
  color: #fff;
  font-weight: 300; }

.styles_advantagesBlock__1AgLa {
  text-align: left;
  margin-top: 30px; }
  .styles_advantagesBlock__1AgLa p {
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 10px;
    font-weight: 300; }
    .styles_advantagesBlock__1AgLa p span {
      margin: 0 20px;
      font-size: 24px; }
  .styles_advantagesBlock__1AgLa p:last-child {
    margin-bottom: 0; }

.styles_copyrightBlock__3IL8r {
  margin-top: 162px;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  font-weight: 300; }

.styles_copyrightBlockBottom__3auqn {
  display: none; }

.styles_rightBar__3emim {
  padding: 20px 30px;
  display: flex;
  flex-direction: column; }

@media only screen and (max-width: 1199px) {
  .styles_container__2XiS4 {
    max-width: 480px; }
  .styles_topBlock__1yFQo {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .styles_tagLine__28jYD {
    padding: 10px 0 0 0;
    text-align: center; }
  .styles_welcome__37SHF {
    margin-top: 22px;
    text-align: center;
    font-size: 48px; }
  .styles_advantagesBlock__1AgLa p span {
    display: block;
    opacity: 0; }
  .styles_abortRegistration__2e3J5 {
    top: 20px; }
  .styles_resetPadding__2WRKm {
    height: 100%; }
    .styles_resetPadding__2WRKm .styles_leftBar__Zynm8 {
      height: 302px;
      padding: 25px; }
  .styles_mainLogo__3IPOx {
    width: 100px;
    height: auto; }
  .styles_leftBar__Zynm8 p {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    display: inline;
    padding: 5px 0px 0px 0px; }
  .styles_leftBar__Zynm8 .styles_advantagesBlock__1AgLa {
    text-align: center;
    margin-top: 10px; }
    .styles_leftBar__Zynm8 .styles_advantagesBlock__1AgLa p span {
      display: inline-block;
      margin: 0px 5px;
      opacity: 1;
      font-size: 20px;
      position: relative;
      top: 2px; }
  .styles_rightBar__3emim {
    padding: 10px;
    display: flex;
    flex-direction: column; }
  .styles_logoDisplay__2Nc9C {
    display: none; }
  .styles_logo__OyTk1 img {
    max-width: 50%; }
  .styles_copyrightBlock__3IL8r {
    display: none; }
  .styles_copyrightBlockBottom__3auqn {
    font-family: Poppins;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #697578;
    margin-top: 30px; } }

@media only screen and (max-width: 1200px) {
  .style_welcomeLayoutLeftBar__2OM6r {
    display: none; } }

.question_container__2LJf2 {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  min-height: 450px; }

.question_titleContainer__AiOso {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .question_titleContainer__AiOso .question_title__sh4xh {
    width: 100%;
    margin-top: 48px;
    display: inline-flex;
    align-items: baseline; }
    .question_titleContainer__AiOso .question_title__sh4xh span {
      font-size: 22px;
      color: #ef4639;
      font-weight: 600; }
      .question_titleContainer__AiOso .question_title__sh4xh span i {
        cursor: pointer; }
    .question_titleContainer__AiOso .question_title__sh4xh h4 {
      flex: 2 1;
      font-size: 20px;
      line-height: 30px;
      color: #697578;
      font-weight: 500;
      text-align: center; }
  .question_titleContainer__AiOso .question_planDescription__bXR4x {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    width: 194px;
    height: 28px;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    color: #697578; }

.question_chooseContainer__m4gOh {
  width: 100%;
  margin-top: 53px; }

.question_questionBlocksContainer__2iG9M {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center; }
  .question_questionBlocksContainer__2iG9M > div {
    cursor: pointer; }

.question_questionBlockRed__1ygfz, .question_questionBlockWhite__2H1Kr {
  width: 330px;
  height: 110px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.question_questionTitle__39jAL {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 100%;
  text-align: center; }

.question_questionDescription__1Fmuf {
  margin-top: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center; }

.question_questionBlockRed__1ygfz {
  background: #EF4639; }
  .question_questionBlockRed__1ygfz .question_questionTitle__39jAL, .question_questionBlockRed__1ygfz .question_questionDescription__1Fmuf {
    color: white; }

.question_questionBlockWhite__2H1Kr {
  margin-top: 20px;
  border: 1px solid #EF4639;
  background: white; }
  .question_questionBlockWhite__2H1Kr .question_questionTitle__39jAL, .question_questionBlockWhite__2H1Kr .question_questionDescription__1Fmuf {
    color: #EF4639; }

@media only screen and (max-width: 480px) {
  .question_plansContainer__3Y6HJ {
    justify-content: space-around; } }

@media only screen and (max-width: 1200px) {
  h4 {
    margin: 0; } }

.progress-bar_container__yGKPv {
  margin-top: -30px;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-bottom: 40px; }

.progress-bar_progressBar__1-xGv, .progress-bar_progressBarTrial__1Nhe5 {
  counter-reset: step; }

.progress-bar_progressBarTrial__1Nhe5 li {
  float: left;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  color: #697578;
  line-height: 14px;
  text-align: center; }

.progress-bar_progressBarTrial__1Nhe5 li:first-child {
  width: 60%; }

.progress-bar_progressBarTrial__1Nhe5 li:last-child {
  width: 27%; }

.progress-bar_progressBar__1-xGv li {
  float: left;
  width: 25%;
  position: relative;
  font-size: 12px;
  color: #697578;
  line-height: 14px;
  text-align: center;
  font-weight: 500; }

.progress-bar_progressBar__1-xGv li:before, .progress-bar_progressBarTrial__1Nhe5 li:before {
  content: counter(step);
  counter-increment: step;
  width: 19px;
  height: 19px;
  border: 1px solid #ececec;
  display: block;
  margin: 0 auto 5px auto;
  border-radius: 50%;
  line-height: 18px;
  background: #ececec;
  color: #697578;
  text-align: center;
  font-weight: 500; }

.progress-bar_progressBar__1-xGv li:first-child:before, .progress-bar_progressBarTrial__1Nhe5 li:first-child:before {
  border: 1px solid #ef4639;
  background: #ef4639;
  color: #ffffff; }

.progress-bar_progressBar__1-xGv li:after, .progress-bar_progressBarTrial__1Nhe5 li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #ececec;
  top: 10px;
  left: -50%;
  z-index: -1; }

.progress-bar_progressBarTrial__1Nhe5 li:after {
  width: 145%;
  left: -100%; }

.progress-bar_progressBar__1-xGv li.progress-bar_active__3S4EQ, .progress-bar_progressBarTrial__1Nhe5 li:after {
  color: #697578; }

.progress-bar_progressBar__1-xGv li.progress-bar_active__3S4EQ::before, .progress-bar_progressBarTrial__1Nhe5 li.progress-bar_active__3S4EQ::before {
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  background: #fff;
  border-color: #ef4639;
  color: #ef4639; }

.progress-bar_progressBar__1-xGv li:first-child:after, .progress-bar_progressBarTrial__1Nhe5 li:first-child:after {
  display: none; }

.progress-bar_progressBar__1-xGv li.progress-bar_current__QT5TG, li.progress-bar_active__3S4EQ {
  color: #ef4639; }

.progress-bar_progressBarTrial__1Nhe5 li.progress-bar_current__QT5TG, li.progress-bar_active__3S4EQ {
  color: #ef4639; }

.progress-bar_progressBar__1-xGv li.progress-bar_current__QT5TG::after, li.progress-bar_active__3S4EQ::after {
  background: #ef4639; }

.progress-bar_progressBarTrial__1Nhe5 li.progress-bar_current__QT5TG::after, li.progress-bar_active__3S4EQ::after {
  background: #ef4639; }

.progress-bar_progressBar__1-xGv li.progress-bar_current__QT5TG::before, .progress-bar_progressBarTrial__1Nhe5 li.progress-bar_current__QT5TG::before {
  border-color: #ef4639;
  background: #ef4639;
  color: #ffffff; }

.progress-bar_progressBar__1-xGv li.progress-bar_active__3S4EQ::before, .progress-bar_progressBarTrial__1Nhe5 li.progress-bar_active__3S4EQ::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABmSURBVHgBnY67DYAwDEQv3iAbwBYRFQ2wBpswCsxBRZkxYADkDWJMQcRHIojXnfXsM/ABLl1mklJT9JCwJCWu3RDPauheJYXs5OcA5FxpxbXO2NG3xyz+uOo2CQlIw016sMvnul9sj4Ap4dL1kA8AAAAASUVORK5CYII=); }

.plan_planCardContainer__2pW0g {
  width: 32%;
  height: 265px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ececec;
  border-radius: 5px;
  background: #fcfcfc; }
  .plan_planCardContainer__2pW0g div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1; }

.plan_durationContainer__ORMTf {
  border-bottom: 1px solid #ececec;
  font-size: 18px;
  line-height: 18px;
  color: #697578;
  text-transform: uppercase;
  font-weight: bold; }

.plan_profitContainer__24b5i {
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  color: #697578;
  font-weight: 400; }

.plan_profitMsg__3llnx {
  color: #ef4639; }

.plan_totalPriceContainer__3PYZ6 {
  flex-direction: column; }
  .plan_totalPriceContainer__3PYZ6 .plan_totalPrice__2D4sq {
    font-size: 30px;
    line-height: 30px;
    color: #212529;
    font-weight: 500; }
  .plan_totalPriceContainer__3PYZ6 .plan_interval__pBrVh {
    font-size: 12px;
    color: #697578; }

.plan_chooseContainer__21aCL {
  flex-direction: column; }
  .plan_chooseContainer__21aCL button {
    width: 94px;
    height: 37px;
    background: #ef4639;
    border-radius: 24px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center; }

@media only screen and (max-width: 1200px) {
  .plan_planCardContainer__2pW0g {
    width: 30%; } }

.subscription-plan_container__1qHT5 {
  display: inline-flex;
  flex-direction: column;
  width: 100%; }

.subscription-plan_titleContainer__2uPYq {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .subscription-plan_titleContainer__2uPYq .subscription-plan_title__2bzNT {
    width: 100%;
    display: inline-flex;
    align-items: baseline; }
    .subscription-plan_titleContainer__2uPYq .subscription-plan_title__2bzNT span {
      font-size: 22px;
      color: #ef4639;
      font-weight: 600; }
      .subscription-plan_titleContainer__2uPYq .subscription-plan_title__2bzNT span i {
        cursor: pointer; }
    .subscription-plan_titleContainer__2uPYq .subscription-plan_title__2bzNT h4 {
      flex: 2 1;
      font-size: 20px;
      line-height: 30px;
      color: #697578;
      font-weight: 500;
      text-align: center; }
  .subscription-plan_titleContainer__2uPYq .subscription-plan_planDescription__2dhZ6 {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    width: 194px;
    height: 28px;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    color: #697578;
    font-weight: 500; }

.subscription-plan_plansContainer__2wsMX {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between; }

.subscription-plan_cancelInfo__4Ilt1 {
  margin-top: 20px;
  text-align: center;
  font-size: 11px;
  line-height: 14px;
  color: #697578;
  font-weight: 500; }

@media only screen and (max-width: 480px) {
  .subscription-plan_plansContainer__2wsMX {
    justify-content: space-around; } }

.personal-data_input__3R9mm {
  width: 200px; }

.personal-data_titleContainer__1MEnH {
  width: 100%;
  display: inline-flex;
  align-items: baseline; }
  .personal-data_titleContainer__1MEnH span {
    flex-basis: 22%;
    font-size: 22px;
    color: #ef4639;
    font-weight: 600; }
    .personal-data_titleContainer__1MEnH span i {
      cursor: pointer; }
  .personal-data_titleContainer__1MEnH h4 {
    font-size: 20px;
    line-height: 30px;
    color: #697578;
    font-weight: 500; }

.personal-data_nameContainer__3TNPD {
  margin-top: 20px;
  display: flex;
  justify-content: space-between; }

.personal-data_usernameContainer__1wd8b {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; }
  .personal-data_usernameContainer__1wd8b img {
    margin: 20px 0 0 20px;
    width: 16px;
    height: 16px; }
  .personal-data_usernameContainer__1wd8b .personal-data_userAvailable__2q8WB {
    word-break: break-word;
    margin: 10px 0 0 20px;
    font-size: 12px;
    line-height: 16px;
    color: #1db61a; }
  .personal-data_usernameContainer__1wd8b .personal-data_userNotAvailable__A1lQ7 {
    margin: 10px 0 0 20px;
    font-size: 12px;
    line-height: 16px;
    color: #ef4639; }

.personal-data_passwordContainer__1lZW3 {
  display: flex;
  justify-content: space-between; }

.personal-data_policyContainer__Lz6GA {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .personal-data_policyContainer__Lz6GA input {
    width: 20px;
    height: 20px;
    border: 1px solid #ececec;
    border-radius: 5px;
    background-color: #fcfcfc; }
  .personal-data_policyContainer__Lz6GA label {
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 16px;
    color: #697578; }
    .personal-data_policyContainer__Lz6GA label a {
      padding: 0 2px;
      color: #ef4639; }

.personal-data_submitContainer__Y3bie {
  display: flex;
  justify-content: center;
  margin-top: 20px; }
  .personal-data_submitContainer__Y3bie button {
    width: 105px;
    height: 48px;
    background: #ef4639;
    border-radius: 24px;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center; }
    .personal-data_submitContainer__Y3bie button[disabled] {
      background-color: #ececec;
      color: #697578; }
  .personal-data_submitContainer__Y3bie i {
    margin-left: 11px;
    font-size: 16px; }

.personal-data_errAcceptTerms__2258U {
  margin-top: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #ef4639; }

@media only screen and (max-width: 1200px) {
  .personal-data_nameContainer__3TNPD,
  .personal-data_passwordContainer__1lZW3 {
    flex-direction: column; }
  .personal-data_passwordContainer__1lZW3 {
    margin-top: 0px; }
  .personal-data_usernameContainer__1wd8b {
    margin-top: 0px;
    flex-direction: column;
    align-items: normal; }
    .personal-data_usernameContainer__1wd8b .personal-data_userAvailable__2q8WB,
    .personal-data_usernameContainer__1wd8b .personal-data_userNotAvailable__A1lQ7 {
      margin: 0px 0px 16px 0px; }
      .personal-data_usernameContainer__1wd8b .personal-data_userAvailable__2q8WB br,
      .personal-data_usernameContainer__1wd8b .personal-data_userNotAvailable__A1lQ7 br {
        display: none; }
  .personal-data_input__3R9mm {
    width: 100%; }
  .personal-data_policyContainer__Lz6GA {
    flex-flow: wrap; }
    .personal-data_policyContainer__Lz6GA .personal-data_errAcceptTerms__2258U {
      margin-left: 0px;
      margin-top: 10px;
      width: 100%; } }

.react-tel-input {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%;
}
.react-tel-input :disabled {
  cursor: not-allowed;
}
.react-tel-input .flag {
  width: 16px;
  height: 11px;
  background: url(/static/media/flags.723494a4.png);
}
.react-tel-input .ad {
  background-position: -16px 0;
}
.react-tel-input .ae {
  background-position: -32px 0;
}
.react-tel-input .af {
  background-position: -48px 0;
}
.react-tel-input .ag {
  background-position: -64px 0;
}
.react-tel-input .ai {
  background-position: -80px 0;
}
.react-tel-input .al {
  background-position: -96px 0;
}
.react-tel-input .am {
  background-position: -112px 0;
}
.react-tel-input .ao {
  background-position: -128px 0;
}
.react-tel-input .ar {
  background-position: -144px 0;
}
.react-tel-input .as {
  background-position: -160px 0;
}
.react-tel-input .at {
  background-position: -176px 0;
}
.react-tel-input .au {
  background-position: -192px 0;
}
.react-tel-input .aw {
  background-position: -208px 0;
}
.react-tel-input .az {
  background-position: -224px 0;
}
.react-tel-input .ba {
  background-position: -240px 0;
}
.react-tel-input .bb {
  background-position: 0 -11px;
}
.react-tel-input .bd {
  background-position: -16px -11px;
}
.react-tel-input .be {
  background-position: -32px -11px;
}
.react-tel-input .bf {
  background-position: -48px -11px;
}
.react-tel-input .bg {
  background-position: -64px -11px;
}
.react-tel-input .bh {
  background-position: -80px -11px;
}
.react-tel-input .bi {
  background-position: -96px -11px;
}
.react-tel-input .bj {
  background-position: -112px -11px;
}
.react-tel-input .bm {
  background-position: -128px -11px;
}
.react-tel-input .bn {
  background-position: -144px -11px;
}
.react-tel-input .bo {
  background-position: -160px -11px;
}
.react-tel-input .br {
  background-position: -176px -11px;
}
.react-tel-input .bs {
  background-position: -192px -11px;
}
.react-tel-input .bt {
  background-position: -208px -11px;
}
.react-tel-input .bw {
  background-position: -224px -11px;
}
.react-tel-input .by {
  background-position: -240px -11px;
}
.react-tel-input .bz {
  background-position: 0 -22px;
}
.react-tel-input .ca {
  background-position: -16px -22px;
}
.react-tel-input .cd {
  background-position: -32px -22px;
}
.react-tel-input .cf {
  background-position: -48px -22px;
}
.react-tel-input .cg {
  background-position: -64px -22px;
}
.react-tel-input .ch {
  background-position: -80px -22px;
}
.react-tel-input .ci {
  background-position: -96px -22px;
}
.react-tel-input .ck {
  background-position: -112px -22px;
}
.react-tel-input .cl {
  background-position: -128px -22px;
}
.react-tel-input .cm {
  background-position: -144px -22px;
}
.react-tel-input .cn {
  background-position: -160px -22px;
}
.react-tel-input .co {
  background-position: -176px -22px;
}
.react-tel-input .cr {
  background-position: -192px -22px;
}
.react-tel-input .cu {
  background-position: -208px -22px;
}
.react-tel-input .cv {
  background-position: -224px -22px;
}
.react-tel-input .cw {
  background-position: -240px -22px;
}
.react-tel-input .cy {
  background-position: 0 -33px;
}
.react-tel-input .cz {
  background-position: -16px -33px;
}
.react-tel-input .de {
  background-position: -32px -33px;
}
.react-tel-input .dj {
  background-position: -48px -33px;
}
.react-tel-input .dk {
  background-position: -64px -33px;
}
.react-tel-input .dm {
  background-position: -80px -33px;
}
.react-tel-input .do {
  background-position: -96px -33px;
}
.react-tel-input .dz {
  background-position: -112px -33px;
}
.react-tel-input .ec {
  background-position: -128px -33px;
}
.react-tel-input .ee {
  background-position: -144px -33px;
}
.react-tel-input .eg {
  background-position: -160px -33px;
}
.react-tel-input .er {
  background-position: -176px -33px;
}
.react-tel-input .es {
  background-position: -192px -33px;
}
.react-tel-input .et {
  background-position: -208px -33px;
}
.react-tel-input .fi {
  background-position: -224px -33px;
}
.react-tel-input .fj {
  background-position: -240px -33px;
}
.react-tel-input .fk {
  background-position: 0 -44px;
}
.react-tel-input .fm {
  background-position: -16px -44px;
}
.react-tel-input .fo {
  background-position: -32px -44px;
}
.react-tel-input .fr,
.react-tel-input .bl,
.react-tel-input .mf {
  background-position: -48px -44px;
}
.react-tel-input .ga {
  background-position: -64px -44px;
}
.react-tel-input .gb {
  background-position: -80px -44px;
}
.react-tel-input .gd {
  background-position: -96px -44px;
}
.react-tel-input .ge {
  background-position: -112px -44px;
}
.react-tel-input .gf {
  background-position: -128px -44px;
}
.react-tel-input .gh {
  background-position: -144px -44px;
}
.react-tel-input .gi {
  background-position: -160px -44px;
}
.react-tel-input .gl {
  background-position: -176px -44px;
}
.react-tel-input .gm {
  background-position: -192px -44px;
}
.react-tel-input .gn {
  background-position: -208px -44px;
}
.react-tel-input .gp {
  background-position: -224px -44px;
}
.react-tel-input .gq {
  background-position: -240px -44px;
}
.react-tel-input .gr {
  background-position: 0 -55px;
}
.react-tel-input .gt {
  background-position: -16px -55px;
}
.react-tel-input .gu {
  background-position: -32px -55px;
}
.react-tel-input .gw {
  background-position: -48px -55px;
}
.react-tel-input .gy {
  background-position: -64px -55px;
}
.react-tel-input .hk {
  background-position: -80px -55px;
}
.react-tel-input .hn {
  background-position: -96px -55px;
}
.react-tel-input .hr {
  background-position: -112px -55px;
}
.react-tel-input .ht {
  background-position: -128px -55px;
}
.react-tel-input .hu {
  background-position: -144px -55px;
}
.react-tel-input .id {
  background-position: -160px -55px;
}
.react-tel-input .ie {
  background-position: -176px -55px;
}
.react-tel-input .il {
  background-position: -192px -55px;
}
.react-tel-input .in {
  background-position: -208px -55px;
}
.react-tel-input .io {
  background-position: -224px -55px;
}
.react-tel-input .iq {
  background-position: -240px -55px;
}
.react-tel-input .ir {
  background-position: 0 -66px;
}
.react-tel-input .is {
  background-position: -16px -66px;
}
.react-tel-input .it {
  background-position: -32px -66px;
}
.react-tel-input .jm {
  background-position: -48px -66px;
}
.react-tel-input .jo {
  background-position: -64px -66px;
}
.react-tel-input .jp {
  background-position: -80px -66px;
}
.react-tel-input .ke {
  background-position: -96px -66px;
}
.react-tel-input .kg {
  background-position: -112px -66px;
}
.react-tel-input .kh {
  background-position: -128px -66px;
}
.react-tel-input .ki {
  background-position: -144px -66px;
}
.react-tel-input .km {
  background-position: -160px -66px;
}
.react-tel-input .kn {
  background-position: -176px -66px;
}
.react-tel-input .kp {
  background-position: -192px -66px;
}
.react-tel-input .kr {
  background-position: -208px -66px;
}
.react-tel-input .kw {
  background-position: -224px -66px;
}
.react-tel-input .ky {
  background-position: -240px -66px;
}
.react-tel-input .kz {
  background-position: 0 -77px;
}
.react-tel-input .la {
  background-position: -16px -77px;
}
.react-tel-input .lb {
  background-position: -32px -77px;
}
.react-tel-input .lc {
  background-position: -48px -77px;
}
.react-tel-input .li {
  background-position: -64px -77px;
}
.react-tel-input .lk {
  background-position: -80px -77px;
}
.react-tel-input .lr {
  background-position: -96px -77px;
}
.react-tel-input .ls {
  background-position: -112px -77px;
}
.react-tel-input .lt {
  background-position: -128px -77px;
}
.react-tel-input .lu {
  background-position: -144px -77px;
}
.react-tel-input .lv {
  background-position: -160px -77px;
}
.react-tel-input .ly {
  background-position: -176px -77px;
}
.react-tel-input .ma {
  background-position: -192px -77px;
}
.react-tel-input .mc {
  background-position: -208px -77px;
}
.react-tel-input .md {
  background-position: -224px -77px;
}
.react-tel-input .me {
  background-position: -112px -154px;
  height: 12px;
}
.react-tel-input .mg {
  background-position: 0 -88px;
}
.react-tel-input .mh {
  background-position: -16px -88px;
}
.react-tel-input .mk {
  background-position: -32px -88px;
}
.react-tel-input .ml {
  background-position: -48px -88px;
}
.react-tel-input .mm {
  background-position: -64px -88px;
}
.react-tel-input .mn {
  background-position: -80px -88px;
}
.react-tel-input .mo {
  background-position: -96px -88px;
}
.react-tel-input .mp {
  background-position: -112px -88px;
}
.react-tel-input .mq {
  background-position: -128px -88px;
}
.react-tel-input .mr {
  background-position: -144px -88px;
}
.react-tel-input .ms {
  background-position: -160px -88px;
}
.react-tel-input .mt {
  background-position: -176px -88px;
}
.react-tel-input .mu {
  background-position: -192px -88px;
}
.react-tel-input .mv {
  background-position: -208px -88px;
}
.react-tel-input .mw {
  background-position: -224px -88px;
}
.react-tel-input .mx {
  background-position: -240px -88px;
}
.react-tel-input .my {
  background-position: 0 -99px;
}
.react-tel-input .mz {
  background-position: -16px -99px;
}
.react-tel-input .na {
  background-position: -32px -99px;
}
.react-tel-input .nc {
  background-position: -48px -99px;
}
.react-tel-input .ne {
  background-position: -64px -99px;
}
.react-tel-input .nf {
  background-position: -80px -99px;
}
.react-tel-input .ng {
  background-position: -96px -99px;
}
.react-tel-input .ni {
  background-position: -112px -99px;
}
.react-tel-input .nl,
.react-tel-input .bq {
  background-position: -128px -99px;
}
.react-tel-input .no {
  background-position: -144px -99px;
}
.react-tel-input .np {
  background-position: -160px -99px;
}
.react-tel-input .nr {
  background-position: -176px -99px;
}
.react-tel-input .nu {
  background-position: -192px -99px;
}
.react-tel-input .nz {
  background-position: -208px -99px;
}
.react-tel-input .om {
  background-position: -224px -99px;
}
.react-tel-input .pa {
  background-position: -240px -99px;
}
.react-tel-input .pe {
  background-position: 0 -110px;
}
.react-tel-input .pf {
  background-position: -16px -110px;
}
.react-tel-input .pg {
  background-position: -32px -110px;
}
.react-tel-input .ph {
  background-position: -48px -110px;
}
.react-tel-input .pk {
  background-position: -64px -110px;
}
.react-tel-input .pl {
  background-position: -80px -110px;
}
.react-tel-input .pm {
  background-position: -96px -110px;
}
.react-tel-input .pr {
  background-position: -112px -110px;
}
.react-tel-input .ps {
  background-position: -128px -110px;
}
.react-tel-input .pt {
  background-position: -144px -110px;
}
.react-tel-input .pw {
  background-position: -160px -110px;
}
.react-tel-input .py {
  background-position: -176px -110px;
}
.react-tel-input .qa {
  background-position: -192px -110px;
}
.react-tel-input .re {
  background-position: -208px -110px;
}
.react-tel-input .ro {
  background-position: -224px -110px;
}
.react-tel-input .rs {
  background-position: -240px -110px;
}
.react-tel-input .ru {
  background-position: 0 -121px;
}
.react-tel-input .rw {
  background-position: -16px -121px;
}
.react-tel-input .sa {
  background-position: -32px -121px;
}
.react-tel-input .sb {
  background-position: -48px -121px;
}
.react-tel-input .sc {
  background-position: -64px -121px;
}
.react-tel-input .sd {
  background-position: -80px -121px;
}
.react-tel-input .se {
  background-position: -96px -121px;
}
.react-tel-input .sg {
  background-position: -112px -121px;
}
.react-tel-input .sh {
  background-position: -128px -121px;
}
.react-tel-input .si {
  background-position: -144px -121px;
}
.react-tel-input .sk {
  background-position: -160px -121px;
}
.react-tel-input .sl {
  background-position: -176px -121px;
}
.react-tel-input .sm {
  background-position: -192px -121px;
}
.react-tel-input .sn {
  background-position: -208px -121px;
}
.react-tel-input .so {
  background-position: -224px -121px;
}
.react-tel-input .sr {
  background-position: -240px -121px;
}
.react-tel-input .ss {
  background-position: 0 -132px;
}
.react-tel-input .st {
  background-position: -16px -132px;
}
.react-tel-input .sv {
  background-position: -32px -132px;
}
.react-tel-input .sx {
  background-position: -48px -132px;
}
.react-tel-input .sy {
  background-position: -64px -132px;
}
.react-tel-input .sz {
  background-position: -80px -132px;
}
.react-tel-input .tc {
  background-position: -96px -132px;
}
.react-tel-input .td {
  background-position: -112px -132px;
}
.react-tel-input .tg {
  background-position: -128px -132px;
}
.react-tel-input .th {
  background-position: -144px -132px;
}
.react-tel-input .tj {
  background-position: -160px -132px;
}
.react-tel-input .tk {
  background-position: -176px -132px;
}
.react-tel-input .tl {
  background-position: -192px -132px;
}
.react-tel-input .tm {
  background-position: -208px -132px;
}
.react-tel-input .tn {
  background-position: -224px -132px;
}
.react-tel-input .to {
  background-position: -240px -132px;
}
.react-tel-input .tr {
  background-position: 0 -143px;
}
.react-tel-input .tt {
  background-position: -16px -143px;
}
.react-tel-input .tv {
  background-position: -32px -143px;
}
.react-tel-input .tw {
  background-position: -48px -143px;
}
.react-tel-input .tz {
  background-position: -64px -143px;
}
.react-tel-input .ua {
  background-position: -80px -143px;
}
.react-tel-input .ug {
  background-position: -96px -143px;
}
.react-tel-input .us {
  background-position: -112px -143px;
}
.react-tel-input .uy {
  background-position: -128px -143px;
}
.react-tel-input .uz {
  background-position: -144px -143px;
}
.react-tel-input .va {
  background-position: -160px -143px;
}
.react-tel-input .vc {
  background-position: -176px -143px;
}
.react-tel-input .ve {
  background-position: -192px -143px;
}
.react-tel-input .vg {
  background-position: -208px -143px;
}
.react-tel-input .vi {
  background-position: -224px -143px;
}
.react-tel-input .vn {
  background-position: -240px -143px;
}
.react-tel-input .vu {
  background-position: 0 -154px;
}
.react-tel-input .wf {
  background-position: -16px -154px;
}
.react-tel-input .ws {
  background-position: -32px -154px;
}
.react-tel-input .ye {
  background-position: -48px -154px;
}
.react-tel-input .za {
  background-position: -64px -154px;
}
.react-tel-input .zm {
  background-position: -80px -154px;
}
.react-tel-input .zw {
  background-position: -96px -154px;
}
.react-tel-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.react-tel-input .hide {
  display: none;
}
.react-tel-input .v-hide {
  visibility: hidden;
}
.react-tel-input input[type=text],
.react-tel-input input[type=tel] {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 110px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 300px;
  outline: none;
}
/* .country-code {
  position: 'absolute';
  left: 55,;
  top: '30%';
} */
.react-tel-input input[type=text].invalid-number,
.react-tel-input input[type=tel].invalid-number {
  border: 1px solid #d79f9f;
  background-color: #FAF0F0;
  border-left-color: #cacaca;
}
.react-tel-input input[type=text].invalid-number:focus,
.react-tel-input input[type=tel].invalid-number:focus {
  border: 1px solid #d79f9f;
  border-left-color: #cacaca;
  background-color: #FAF0F0;
}
.react-tel-input .flag-dropdown {
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #eaeaea;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .flag-dropdown.open-dropdown {
  background: #fff;
  border-radius: 3px 0 0 0;
}
.react-tel-input .flag-dropdown.open-dropdown .selected-flag {
  background: #fff;
  border-radius: 3px 0 0 0;
}
.react-tel-input .flag-dropdown:hover,
.react-tel-input .flag-dropdown:focus {
  cursor: pointer;
}
.react-tel-input .flag-dropdown:hover .selected-flag,
.react-tel-input .flag-dropdown:focus .selected-flag {
  background-color: #fff;
}
.react-tel-input input[disabled] + .flag-dropdown:hover {
  cursor: default;
}
.react-tel-input input[disabled] + .flag-dropdown:hover .selected-flag {
  background-color: transparent;
}
.react-tel-input .selected-flag {
  z-index: 13;
  position: relative;
  width: 88px;
  height: 100%;
  padding: 0 0 0 20px;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -5px;
}
.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
.react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid #555;
}
.react-tel-input .country-list {
  list-style: none;
  position: absolute;
  z-index: 15;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  background-color: white;
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}
.react-tel-input .country-list .flag {
  display: inline-block;
}
.react-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
}
.react-tel-input .country-list .country {
  padding: 7px 10px;
}
.react-tel-input .country-list .country .dial-code {
  color: #6b6b6b;
}
.react-tel-input .country-list .country:hover {
  background-color: #f1f1f1;
}
.react-tel-input .country-list .country.highlight {
  background-color: #e8e8e8;
}
.react-tel-input .country-list .flag {
  margin-right: 6px;
  margin-top: 2px;
}
.react-tel-input .country-list .country-name {
  margin-right: 6px;
}
.react-tel-input .country-list .search {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 10px 0 6px 10px;
}
.react-tel-input .country-list .search-emoji {
  font-size: 15px;
  vertical-align: -2px;
}
.react-tel-input .country-list .search-box {
  border: 1px solid #cacaca;
  border-radius: 3px;
  font-size: 15px;
  line-height: 15px;
  margin-left: 6px;
  padding: 3px 8px 5px;
  outline: none;
}
.react-tel-input .country-list .no-entries-message {
  padding: 7px 10px 11px;
  opacity: .7;
}
.form-control::-webkit-input-placeholder {
  font-size: 14px;
  color: #999;
  opacity: 1; /* Firefox */
}
.form-control::-moz-placeholder {
  font-size: 14px;
  color: #999;
  opacity: 1; /* Firefox */
}
.form-control::-ms-input-placeholder {
  font-size: 14px;
  color: #999;
  opacity: 1; /* Firefox */
}
.form-control::placeholder {
  font-size: 14px;
  color: #999;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  font-size: 14px;
  color: #999;
}

.form-control::-ms-input-placeholder {
  font-size: 14px;
  color: #999;
}
.identity-data_container__1c6zA button {
  width: 121px;
  height: 48px;
  background: #ececec;
  border-radius: 24px;
  border: 0;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #697578;
  display: flex;
  justify-content: center;
  align-items: center; }
  .identity-data_container__1c6zA button:disabled {
    cursor: auto; }

.identity-data_container__1c6zA .identity-data_phoneErr__WBqh0 {
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #ef4639; }

.identity-data_titleContainer__1arJ6 {
  width: 100%;
  display: inline-flex;
  align-items: baseline; }
  .identity-data_titleContainer__1arJ6 span {
    flex: 1 1;
    font-size: 22px;
    color: #ef4639;
    font-weight: 600; }
    .identity-data_titleContainer__1arJ6 span i {
      cursor: pointer; }
  .identity-data_titleContainer__1arJ6 h4 {
    flex: 2 1;
    font-size: 20px;
    line-height: 30px;
    color: #697578;
    font-weight: 400; }

.identity-data_phoneContainer__nTzip {
  margin-top: 20px;
  display: flex; }
  .identity-data_phoneContainer__nTzip div {
    flex: 2 1; }
  .identity-data_phoneContainer__nTzip button {
    margin-left: 10px;
    flex: 1 1; }
    .identity-data_phoneContainer__nTzip button i {
      display: none; }
    .identity-data_phoneContainer__nTzip button.identity-data_active__2L1U0 {
      background: #ef4639;
      color: #fff; }
    .identity-data_phoneContainer__nTzip button.identity-data_verified__2X4HL {
      background: #4d7e83; }
      .identity-data_phoneContainer__nTzip button.identity-data_verified__2X4HL i {
        display: inline;
        font-size: 16px;
        color: #fff; }
      .identity-data_phoneContainer__nTzip button.identity-data_verified__2X4HL span {
        display: none; }
    .identity-data_phoneContainer__nTzip button:disabled {
      cursor: auto; }

.identity-data_emailContainer__3kZ9w {
  margin-top: 20px;
  display: flex; }
  .identity-data_emailContainer__3kZ9w div {
    flex: 2 1; }
  .identity-data_emailContainer__3kZ9w button {
    margin-left: 10px;
    flex: 1 1; }
    .identity-data_emailContainer__3kZ9w button i {
      display: none; }
    .identity-data_emailContainer__3kZ9w button.identity-data_active__2L1U0 {
      background: #ef4639;
      color: #fff; }
    .identity-data_emailContainer__3kZ9w button.identity-data_verified__2X4HL {
      background: #4d7e83; }
      .identity-data_emailContainer__3kZ9w button.identity-data_verified__2X4HL i {
        display: inline;
        font-size: 16px;
        color: #fff; }
      .identity-data_emailContainer__3kZ9w button.identity-data_verified__2X4HL span {
        display: none; }
    .identity-data_emailContainer__3kZ9w button:disabled {
      cursor: auto; }

.identity-data_submitContainer__Eb9Ow {
  margin-top: 20px;
  display: flex;
  justify-content: center; }
  .identity-data_submitContainer__Eb9Ow button {
    width: 105px;
    height: 48px;
    background: #ef4639;
    color: #fff; }
    .identity-data_submitContainer__Eb9Ow button i {
      display: inline;
      margin-left: 10px; }
    .identity-data_submitContainer__Eb9Ow button:disabled {
      color: #697578;
      background: #ececec;
      cursor: auto; }

.credit-card_container__pm7zy {
  flex-direction: column;
  display: flex;
  width: 100%; }

.credit-card_titleContainer___d6Tm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; }
  .credit-card_titleContainer___d6Tm .credit-card_title__2BLCJ {
    width: 100%;
    display: inline-flex;
    align-items: center; }
    .credit-card_titleContainer___d6Tm .credit-card_title__2BLCJ span {
      font-size: 22px;
      color: #ef4639;
      font-weight: 600; }
      .credit-card_titleContainer___d6Tm .credit-card_title__2BLCJ span i {
        cursor: pointer; }
    .credit-card_titleContainer___d6Tm .credit-card_title__2BLCJ h4 {
      flex: 2 1;
      font-size: 20px;
      line-height: 30px;
      color: #697578;
      font-weight: 500;
      text-align: center; }

.credit-card_plan__1cowd {
  background: #ECECEC;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 100px;
  margin: 0 5px 20px 5px;
  height: 32px;
  min-width: 240px; }

.credit-card_cc__37Jw_ {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 4px; }
  .credit-card_cc__37Jw_ #nb {
    min-width: 140px; }
  .credit-card_cc__37Jw_ #date {
    min-width: 43px; }
  .credit-card_cc__37Jw_ #cvc {
    min-width: 43px; }

.credit-card_fieldWrapper__2l7BI {
  background: #fcfcfc;
  border: 1px solid #ececec;
  margin: 0px 5px 10px;
  flex-direction: row;
  height: 48px;
  border-radius: 50px;
  padding: 8px 14px;
  text-align: center;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  flex-grow: 1; }
  .credit-card_fieldWrapper__2l7BI.credit-card_hasError___WIsf {
    border: 1px solid #EF4639; }

.credit-card_title__2BLCJ {
  font-family: Poppins;
  font-size: 10px;
  line-height: 15px;
  text-align: left;
  color: #999999; }

.credit-card_field__22l04 {
  height: auto;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  border: none !important; }

.credit-card_smallFields__3mSOU {
  display: flex; }

.credit-card_cardsError__1Wi3h {
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #ef4639; }

.credit-card_plan__1cowd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px; }
  .credit-card_plan__1cowd .fas {
    margin-right: 14px;
    color: #697578; }

.credit-card_planName__lkyLD {
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #697578;
  text-transform: uppercase; }

.credit-card_priceText__2ETQ_ {
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  color: #212529; }

.credit-card_payButton__3rDcT {
  padding: 13px 30px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  height: 48px;
  border-radius: 100px;
  border: 0;
  outline: none;
  background-color: #EF4639; }
  .credit-card_payButton__3rDcT i {
    margin-left: 10px; }
  .credit-card_payButton__3rDcT .credit-card_buttonLoaderContainer__1iW9y {
    width: 24px;
    height: 24px; }
    .credit-card_payButton__3rDcT .credit-card_buttonLoaderContainer__1iW9y i {
      margin-top: 5px; }
  .credit-card_payButton__3rDcT div {
    margin-left: 10px;
    border: 5px solid #ECECEC;
    /* Light grey */
    border-top: 5px solid #697578;
    /* Blue */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    -webkit-animation: credit-card_spin__1DVxO 1s linear infinite;
            animation: credit-card_spin__1DVxO 1s linear infinite; }

@-webkit-keyframes credit-card_spin__1DVxO {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes credit-card_spin__1DVxO {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .credit-card_payButton__3rDcT:disabled {
    background-color: #ECECEC;
    color: #697578;
    -webkit-transition: background-color 0.1s ease;
    transition: background-color 0.1s ease; }

.credit-card_buttonCont__21C3I {
  display: flex;
  justify-content: center; }

.congratulations_congratsContainer__1rl3D {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75%; }
  .congratulations_congratsContainer__1rl3D .congratulations_congratsTitle__2iVFo {
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: #697578;
    font-weight: 500; }
  .congratulations_congratsContainer__1rl3D .congratulations_toLogin__3214N {
    margin-top: 20px;
    width: 195px;
    height: 48px;
    background: #ef4639;
    border-radius: 24px;
    border: 0;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500; }
  .congratulations_congratsContainer__1rl3D i {
    margin-left: 11px; }
  .congratulations_congratsContainer__1rl3D .congratulations_congratsTrialInfo__1tGy6 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #697578;
    max-width: 370px;
    margin-top: 10px; }

.style_title__3wGHf {
    font-size: 20px;
    color: #697578;
    margin-bottom: 40px;
    text-align: center;
}

.style_text__12i8q {
    text-align: center;
}

.planRenewComponent_planRenewCardContainer__1LGv- {
  display: inline-flex;
  justify-content: space-between;
  width: 106%;
  align-content: center;
  margin-left: -3%;
  margin-top: 20px; }

.planRenewComponent_planRenewTitie__Kh_4J {
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #697578; }

.planRenewComponent_planRenewInfo__5vK64 {
  margin-top: 40px;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #697578;
  margin-bottom: 10px; }

.planRenewComponent_planRenewInfoTwo__2T-Ha {
  margin-top: 30px;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #697578;
  margin-bottom: 10px; }

.subscriptionDropdown_dropdownChosen__2KDag {
  width: 100%;
  height: 32px;
  background: #ECECEC;
  border: 1px solid #ECECEC;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  justify-content: space-between; }

.subscriptionDropdown_dropdownText__1wCBH {
  display: flex;
  flex-direction: row;
  align-items: center; }

.subscriptionDropdown_dropdownTextName__3cLOC {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #697578;
  text-transform: uppercase;
  width: 112px; }

.subscriptionDropdown_dropdownTextPrice__A2VGt {
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #212529;
  margin-left: 36px;
  width: 116px; }
  .subscriptionDropdown_dropdownTextPrice__A2VGt span {
    font-weight: 400; }

.subscriptionDropdown_dropdownArrow__VjRAx {
  padding: 14px; }

.subscriptionDropdown_dropdownTitle__2niBQ {
  margin-top: 5px; }

.subscriptionDropdown_dropdownPer__1gal5 {
  font-size: 12px;
  text-transform: capitalize; }

.subscriptionDropdown_dropdownTitle__2niBQ {
  padding-left: 9px;
  background: #ECECEC;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  height: 32px;
  color: #697578;
  text-transform: uppercase;
  display: flex;
  align-items: center; }

.subscriptionDropdown_dropdown__2YUVy {
  position: absolute;
  z-index: 2;
  width: 415px; }

.subscriptionDropdown_dropdownPlan__3ekfq {
  display: flex;
  background: #ECECEC;
  height: 32px;
  padding-left: 20px;
  display: flex;
  align-items: center; }
  .subscriptionDropdown_dropdownPlan__3ekfq:nth-child(odd) {
    background: #D9D9D9; }
  .subscriptionDropdown_dropdownPlan__3ekfq:hover {
    background: #EF4639; }
    .subscriptionDropdown_dropdownPlan__3ekfq:hover .subscriptionDropdown_dropdownTextName__3cLOC, .subscriptionDropdown_dropdownPlan__3ekfq:hover .subscriptionDropdown_dropdownTextPrice__A2VGt, .subscriptionDropdown_dropdownPlan__3ekfq:hover .subscriptionDropdown_dropDownTrial__2kUgW {
      color: white; }

.subscriptionDropdown_dropDownTrial__2kUgW {
  margin-left: 10px;
  font-size: 13px;
  line-height: 16px; }

@media only screen and (max-width: 1200px) {
  .subscriptionDropdown_dropdown__2YUVy {
    width: 460px; } }

.renewPlan_titleContainer__jedO6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; }
  .renewPlan_titleContainer__jedO6 .renewPlan_title__uH489 {
    width: 100%;
    display: inline-flex;
    align-items: center; }
    .renewPlan_titleContainer__jedO6 .renewPlan_title__uH489 span {
      font-size: 22px;
      color: #ef4639;
      font-weight: 600; }
      .renewPlan_titleContainer__jedO6 .renewPlan_title__uH489 span i {
        cursor: pointer; }
    .renewPlan_titleContainer__jedO6 .renewPlan_title__uH489 h4 {
      flex: 2 1;
      font-size: 20px;
      line-height: 30px;
      color: #697578;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase; }

.renewPlan_renewCardContainer__Z22s9 {
  margin-top: 20px;
  width: 100%; }

.renewPlan_renewButton__18Mc9 {
  width: 105px;
  height: 48px;
  background: #EF4639;
  border-radius: 24px;
  color: white;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: none; }
  .renewPlan_renewButton__18Mc9:disabled {
    color: #697578;
    background: #ececec;
    cursor: auto; }
  .renewPlan_renewButton__18Mc9 span {
    font-size: 14px;
    margin-right: 8px;
    font-weight: 500; }

.login_loginContainer__h6F1b {
  padding: 20px 0px 0px 0px; }
  .login_loginContainer__h6F1b .login_loginChooseBlock__2b5_g {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    color: #697578; }
    .login_loginContainer__h6F1b .login_loginChooseBlock__2b5_g h4 {
      text-align: center;
      font-size: 20px;
      color: #697578;
      text-transform: uppercase;
      font-weight: 400;
      display: inline-block;
      margin-right: 6px; }
    .login_loginContainer__h6F1b .login_loginChooseBlock__2b5_g a {
      color: #ee4639;
      margin: 0 0 0 6px;
      text-decoration: underline;
      font-size: 20px; }
  .login_loginContainer__h6F1b .login_formSignin__2SoDd {
    width: 100%;
    max-width: 420px;
    margin: auto;
    padding: 0px 10px; }
  .login_loginContainer__h6F1b .login_singinForgotpassBlock__1cdrt {
    display: flex;
    justify-content: space-between;
    align-items: baseline; }
    .login_loginContainer__h6F1b .login_singinForgotpassBlock__1cdrt .login_forgotPass__KDHTe a {
      color: #ee4639;
      font-size: 16px;
      line-height: 24px; }
    .login_loginContainer__h6F1b .login_singinForgotpassBlock__1cdrt button {
      width: 127px;
      height: 48px;
      background: #ef4639;
      border-radius: 24px;
      border: 0;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center; }
      .login_loginContainer__h6F1b .login_singinForgotpassBlock__1cdrt button:hover {
        background: #4d7e83;
        -webkit-transition: 0.4s;
        transition: 0.4s; }
      .login_loginContainer__h6F1b .login_singinForgotpassBlock__1cdrt button i {
        font-size: 18px;
        margin-left: 11px;
        color: #fff;
        line-height: 21px; }
  .login_loginContainer__h6F1b .login_marketBlock__IBiTR {
    display: flex;
    justify-content: space-between;
    margin: 130px 0px 0px 0px; }

.login_resetPadding__1snbJ h2 {
  height: 240px; }

.login_loginSplText__Htu3z {
  display: block;
  text-align: center;
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  padding: 17px 0 0 0; }

.login_loginRightInn__2ULV8 {
  height: 340px; }
  .login_loginRightInn__2ULV8 h4 {
    font-size: 20px;
    color: #697578;
    margin-bottom: 40px;
    text-align: center; }
  .login_loginRightInn__2ULV8 button {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: #ef4639;
    border: 0;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right; }
    .login_loginRightInn__2ULV8 button:hover {
      background: #4d7e83;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
    .login_loginRightInn__2ULV8 button i {
      font-size: 40px; }

.login_dontHaveAccount__1n4jt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; }
  .login_dontHaveAccount__1n4jt span {
    display: block;
    width: 100%;
    border-top: 1px solid #f2f2f2;
    text-align: center;
    color: #e74437;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding: 17px 0 0 0; }
  .login_dontHaveAccount__1n4jt p {
    text-align: center;
    font-size: 16px;
    color: #a6a6a6;
    font-weight: 400;
    padding: 10px 0; }
    .login_dontHaveAccount__1n4jt p a {
      color: #ee4639;
      text-decoration: underline; }

@media only screen and (max-width: 1200px) {
  .login_loginRightBarLogo__TLhsL {
    display: none; }
  .login_welcomeLayoutLeftBar__1Fzs2 {
    display: none; }
  h4 {
    margin: 20px 0px 40px 0px; } }

@media only screen and (max-width: 768px) {
  .login_loginContainer__h6F1b .login_loginChooseBlock__2b5_g {
    margin-top: 20px;
    margin-bottom: 25px; }
  .login_loginContainer__h6F1b .login_singinForgotpassBlock__1cdrt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 130px; }
    .login_loginContainer__h6F1b .login_singinForgotpassBlock__1cdrt button, .login_loginContainer__h6F1b .login_singinForgotpassBlock__1cdrt p {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px auto; }
  .login_loginContainer__h6F1b .login_marketBlock__IBiTR {
    margin-top: 20px;
    justify-content: space-around; }
    .login_loginContainer__h6F1b .login_marketBlock__IBiTR a img {
      width: 180px; } }

@media only screen and (max-width: 480px) {
  .login_loginContainer__h6F1b .login_loginChooseBlock__2b5_g {
    margin-top: 20px;
    margin-bottom: 25px; }
  .login_loginContainer__h6F1b .login_singinForgotpassBlock__1cdrt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 130px; }
    .login_loginContainer__h6F1b .login_singinForgotpassBlock__1cdrt button, .login_loginContainer__h6F1b .login_singinForgotpassBlock__1cdrt p {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px auto; }
  .login_loginContainer__h6F1b .login_marketBlock__IBiTR {
    margin-top: 20px;
    justify-content: space-around; }
    .login_loginContainer__h6F1b .login_marketBlock__IBiTR a img {
      width: 160px; } }

@media only screen and (max-width: 375px) {
  .login_loginContainer__h6F1b .login_marketBlock__IBiTR a img {
    width: 140px; } }

@media only screen and (max-width: 1200px) {
  .forgotPassword_welcomeLayoutLeftBar__Pfhsr {
    display: none; } }

.forgotPassword_loginRightInn__2LWPz {
  height: 240px; }
  .forgotPassword_loginRightInn__2LWPz h4 {
    font-size: 20px;
    color: #697578;
    margin-bottom: 40px;
    text-align: center; }
  .forgotPassword_loginRightInn__2LWPz h2 {
    color: #697578;
    text-align: center;
    font-size: 16px;
    margin-bottom: 40px;
    line-height: 30px; }
    .forgotPassword_loginRightInn__2LWPz h2 span {
      display: block;
      font-weight: 400;
      padding: 17px 0px 0px 0px; }
  .forgotPassword_loginRightInn__2LWPz button {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: #ef4639;
    border: 0;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right; }
    .forgotPassword_loginRightInn__2LWPz button:hover {
      background: #4d7e83;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
    .forgotPassword_loginRightInn__2LWPz button i {
      font-size: 40px; }

.forgotPassword_dontHaveAccount__3QKqp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; }
  .forgotPassword_dontHaveAccount__3QKqp span {
    display: block;
    width: 100%;
    border-top: 1px solid #f2f2f2;
    text-align: center;
    color: #e74437;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding: 17px 0 0 0; }
  .forgotPassword_dontHaveAccount__3QKqp p {
    text-align: center;
    font-size: 16px;
    color: #a6a6a6;
    font-weight: 400;
    padding: 10px 0; }
    .forgotPassword_dontHaveAccount__3QKqp p a {
      color: #ee4639;
      text-decoration: underline; }

.chatListsTab_tabListItem__3Y--t {
  padding-top: 5px;
  padding-bottom: 5px;
  flex: 1 1;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  color: #605655;
  border-bottom: 1px solid #e7eaf1;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  cursor: pointer; }
  .chatListsTab_tabListItem__3Y--t.chatListsTab_tabListActive__NcOmK {
    color: #ef4639;
    border-bottom: 1px solid #ef4639; }
  .chatListsTab_tabListItem__3Y--t:not(.chatListsTab_tabListActive__NcOmK):hover {
    background-color: #E7EAF1; }

.chatSearch_inputContainer__2NnI2 {
  width: 100%; }
  .chatSearch_inputContainer__2NnI2 .chatSearch_searchIcon__17KrK {
    position: absolute;
    padding: 10px 10px 10px 15px;
    color: #989DA1; }
  .chatSearch_inputContainer__2NnI2 .chatSearch_searchInput__3cszb {
    width: 100%;
    height: 35px;
    padding: 0 40px;
    background: #fff;
    border: 1px solid #e7eaf1;
    border-radius: 20.5px; }
    .chatSearch_inputContainer__2NnI2 .chatSearch_searchInput__3cszb::-webkit-input-placeholder {
      font-size: 14px;
      color: #989DA1;
      opacity: 0.5; }
    .chatSearch_inputContainer__2NnI2 .chatSearch_searchInput__3cszb::-moz-placeholder {
      font-size: 14px;
      color: #989DA1;
      opacity: 0.5; }
    .chatSearch_inputContainer__2NnI2 .chatSearch_searchInput__3cszb::-ms-input-placeholder {
      font-size: 14px;
      color: #989DA1;
      opacity: 0.5; }
    .chatSearch_inputContainer__2NnI2 .chatSearch_searchInput__3cszb::placeholder {
      font-size: 14px;
      color: #989DA1;
      opacity: 0.5; }
  .chatSearch_inputContainer__2NnI2 .chatSearch_closeButtonContainer__3e-uc {
    position: absolute;
    top: 7px;
    right: 23px;
    width: 21px;
    height: 21px;
    background: #FAFAFA;
    border: 1px solid #E7EAF1;
    border-radius: 50%;
    visibility: hidden; }
    .chatSearch_inputContainer__2NnI2 .chatSearch_closeButtonContainer__3e-uc:hover {
      background: #E7EAF1; }
  .chatSearch_inputContainer__2NnI2 .chatSearch_closeButtonStickOne__1BnAO, .chatSearch_inputContainer__2NnI2 .chatSearch_closeButtonStickTwo__1P6Ay {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 9px;
    background: #605655;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  .chatSearch_inputContainer__2NnI2 .chatSearch_closeButtonStickOne__1BnAO {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg); }
  .chatSearch_inputContainer__2NnI2 .chatSearch_closeButtonStickTwo__1P6Ay {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
            transform: translate(-50%, -50%) rotate(-45deg); }

.optionMenu_optionMenuContainer__1Tz8r {
  display: none;
  padding: 10px 0;
  position: absolute;
  width: 170px;
  z-index: 2;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #E7EAF1;
  background: #fff;
  box-shadow: 0px -10px 34px rgba(0, 0, 0, 0.15); }
  .optionMenu_optionMenuContainer__1Tz8r::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 13px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 10px 10px 10px;
    border-color: transparent transparent #fff transparent; }
  .optionMenu_optionMenuContainer__1Tz8r .optionMenu_option__Rx3uj {
    padding: 5px 20px;
    font-size: 15px;
    line-height: 22px;
    color: #605655;
    cursor: pointer; }
    .optionMenu_optionMenuContainer__1Tz8r .optionMenu_option__Rx3uj:hover {
      background-color: #e2e2e2; }
  .optionMenu_optionMenuContainer__1Tz8r:hover {
    display: flex; }
  .optionMenu_optionMenuContainer__1Tz8r.optionMenu_active__4fqVG {
    display: flex; }

@media (max-width: 575.98px) {
  .optionMenu_optionMenuContainer__1Tz8r::before {
    top: -10px;
    left: 112px; } }

.chatLists_headerChatList__1Bp-r {
  margin: 0 0 40px 0;
  display: flex;
  justify-content: space-between; }
  .chatLists_headerChatList__1Bp-r h4 {
    display: flex;
    align-items: center;
    color: #605655;
    margin: 0;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500; }
  .chatLists_headerChatList__1Bp-r .chatLists_newChatBtn__L9ksx {
    padding: 0 17px;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 121px;
    height: 35px;
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    border-radius: 17.5px;
    background-color: #ef4639;
    cursor: pointer; }
  .chatLists_headerChatList__1Bp-r .chatLists_newChatBtn__L9ksx > i {
    font-size: 11px; }

.chatLists_tabs__1y6XJ {
  position: relative;
  margin-top: 30px; }
  .chatLists_tabs__1y6XJ .chatLists_tabList__YqSeT {
    padding: 0 15px;
    display: flex;
    justify-content: space-around;
    list-style: none; }

.chatLists_newChatBtn__L9ksx:hover .chatLists_newChatMenu__THudN {
  display: flex;
  top: 42px;
  left: 5px; }
  .chatLists_newChatBtn__L9ksx:hover .chatLists_newChatMenu__THudN::after {
    content: '';
    position: absolute;
    top: -7px;
    width: 100%;
    height: 7px; }

.chatLists_chatList__1tu3X {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  border-radius: 3px; }
  .chatLists_chatList__1tu3X::-webkit-scrollbar-button, .chatLists_chatList__1tu3X::-webkit-scrollbar {
    height: 0;
    width: 6px; }
  .chatLists_chatList__1tu3X::-webkit-scrollbar-thumb {
    background-color: transparent; }
  .chatLists_chatList__1tu3X:hover::-webkit-scrollbar-thumb {
    background: #D4D9E3; }
  .chatLists_chatList__1tu3X:hover {
    scrollbar-color: #D4D9E3 transparent; }

@media (min-width: 576px) and (max-width: 767.98px) {
  .chatLists_headerChatList__1Bp-r {
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center; }
    .chatLists_headerChatList__1Bp-r h4 {
      text-align: center;
      font-size: 16px; }
    .chatLists_headerChatList__1Bp-r .chatLists_newChatBtn__L9ksx {
      width: 130px;
      justify-content: space-evenly;
      font-size: 14px; }
  .chatLists_tabs__1y6XJ {
    margin-top: 20px; } }

@media (max-width: 575.98px) {
  .chatLists_headerChatList__1Bp-r {
    margin-bottom: 20px; }
    .chatLists_headerChatList__1Bp-r h4 {
      text-align: center;
      font-size: 16px; }
    .chatLists_headerChatList__1Bp-r .chatLists_newChatBtn__L9ksx {
      width: 130px;
      justify-content: space-evenly;
      font-size: 14px; }
  .chatLists_tabs__1y6XJ {
    margin-top: 20px; }
  .chatLists_newChatBtn__L9ksx:hover .chatLists_newChatMenu__THudN {
    left: auto; } }

.chat_chatContainer__Y_SoD {
  position: static;
  padding: 0 18px;
  padding-right: 0;
  margin-right: 3px;
  height: 55px;
  display: flex;
  align-items: center;
  cursor: pointer; }
  .chat_chatContainer__Y_SoD .chat_redDot__SmjVz {
    display: none; }
  .chat_chatContainer__Y_SoD .chat_chatAvatarContainer__3j4e5 {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: lightgray;
    border-radius: 50%; }
    .chat_chatContainer__Y_SoD .chat_chatAvatarContainer__3j4e5 img {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      font-size: 0;
      border: 1px solid #b9b9b9;
      overflow: hidden; }
    .chat_chatContainer__Y_SoD .chat_chatAvatarContainer__3j4e5 img::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("/frontend/images/no-image.jpg") no-repeat, center;
      background-size: contain; }
    .chat_chatContainer__Y_SoD .chat_chatAvatarContainer__3j4e5 i {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px; }
  .chat_chatContainer__Y_SoD .chat_chatNameMessageInfo__pY6iP {
    margin-left: 10px;
    min-width: 0;
    width: 82%;
    height: 50px;
    display: flex;
    flex-direction: column; }
    .chat_chatContainer__Y_SoD .chat_chatNameMessageInfo__pY6iP h3, .chat_chatContainer__Y_SoD .chat_chatNameMessageInfo__pY6iP p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .chat_chatContainer__Y_SoD .chat_chatNameMessageInfo__pY6iP h3 {
      height: 27px;
      font-size: 18px;
      line-height: 27px;
      font-weight: normal;
      color: #605655; }
    .chat_chatContainer__Y_SoD .chat_chatNameMessageInfo__pY6iP p {
      font-size: 14px;
      line-height: 21px;
      color: #989DA1; }
  .chat_chatContainer__Y_SoD .chat_chatMenuContainer__3hw8G {
    width: 14px;
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    cursor: pointer; }
    .chat_chatContainer__Y_SoD .chat_chatMenuContainer__3hw8G i {
      color: #989DA1; }
  .chat_chatContainer__Y_SoD.chat_activeChat__17ari {
    background: #EF4639; }
    .chat_chatContainer__Y_SoD.chat_activeChat__17ari .chat_chatNameMessageInfo__pY6iP h3, .chat_chatContainer__Y_SoD.chat_activeChat__17ari .chat_chatNameMessageInfo__pY6iP p {
      color: #fff; }
    .chat_chatContainer__Y_SoD.chat_activeChat__17ari .chat_chatMenuContainer__3hw8G i {
      color: #fff; }
  .chat_chatContainer__Y_SoD:not(.chat_activeChat__17ari):hover {
    background: #E7EAF1; }
  .chat_chatContainer__Y_SoD .chat_noPhotoIcon__314wq {
    color: gray; }
  .chat_chatContainer__Y_SoD.chat_pendingChat__1uTme .chat_chatAvatarContainer__3j4e5 {
    min-width: 35px;
    min-height: 35px;
    background: linear-gradient(100deg, #E7EAF1 30%, #f6f7f8 50%, #E7EAF1 70%);
    background-size: 400%;
    -webkit-animation: chat_loading__13ZxA 1.2s ease-in-out infinite;
            animation: chat_loading__13ZxA 1.2s ease-in-out infinite; }
    .chat_chatContainer__Y_SoD.chat_pendingChat__1uTme .chat_chatAvatarContainer__3j4e5 img {
      display: none; }
    .chat_chatContainer__Y_SoD.chat_pendingChat__1uTme .chat_chatAvatarContainer__3j4e5 i {
      display: none; }
  .chat_chatContainer__Y_SoD.chat_pendingChat__1uTme .chat_chatNameMessageInfo__pY6iP {
    justify-content: space-evenly; }
    .chat_chatContainer__Y_SoD.chat_pendingChat__1uTme .chat_chatNameMessageInfo__pY6iP h3, .chat_chatContainer__Y_SoD.chat_pendingChat__1uTme .chat_chatNameMessageInfo__pY6iP p {
      border-radius: 3px;
      color: transparent;
      background: linear-gradient(100deg, #E7EAF1 30%, #f6f7f8 50%, #E7EAF1 70%);
      background-size: 400%;
      -webkit-animation: chat_loading__13ZxA 1.2s ease-in-out infinite;
              animation: chat_loading__13ZxA 1.2s ease-in-out infinite; }
    .chat_chatContainer__Y_SoD.chat_pendingChat__1uTme .chat_chatNameMessageInfo__pY6iP h3 {
      max-width: 140px;
      height: 13px; }
    .chat_chatContainer__Y_SoD.chat_pendingChat__1uTme .chat_chatNameMessageInfo__pY6iP p {
      max-width: 100px;
      height: 10px; }
  .chat_chatContainer__Y_SoD.chat_pendingChat__1uTme .chat_chatMenuContainer__3hw8G {
    display: none; }

@-webkit-keyframes chat_loading__13ZxA {
  0% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes chat_loading__13ZxA {
  0% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }
  .chat_chatContainer__Y_SoD.chat_unread__265NG:not(.chat_pendingChat__1uTme) {
    padding-left: 0; }
    .chat_chatContainer__Y_SoD.chat_unread__265NG:not(.chat_pendingChat__1uTme) .chat_redDot__SmjVz {
      display: block;
      margin: 0 5px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #EF4639; }
    .chat_chatContainer__Y_SoD.chat_unread__265NG:not(.chat_pendingChat__1uTme) .chat_chatNameMessageInfo__pY6iP h3, .chat_chatContainer__Y_SoD.chat_unread__265NG:not(.chat_pendingChat__1uTme) .chat_chatNameMessageInfo__pY6iP p {
      font-weight: 600; }

.chat_chatMenuContainer__3hw8G .chat_chatOptionMenu__1nSnc {
  display: none;
  width: 140px; }

.chatModal_overlay__18wqo {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6); }
  .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    z-index: 1055;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    outline: none; }
    .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe {
      position: relative;
      width: auto;
      min-width: 570px;
      max-width: 600px;
      z-index: 1000;
      max-height: 849px;
      pointer-events: none; }
      .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 {
        position: relative;
        pointer-events: auto;
        width: 100%;
        height: 100%;
        background: #fff;
        border: 0; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_modalChatHeader__3yxRG {
          position: relative;
          display: flex;
          justify-content: center;
          height: 68px;
          background-color: #ef4639; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_modalChatHeader__3yxRG .chatModal_modalTitle__3QVFV {
            display: flex;
            align-items: center;
            font-size: 23px;
            color: #fff; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_modalChatHeader__3yxRG i {
            cursor: pointer;
            position: absolute;
            font-size: 20px;
            color: #fff;
            top: 24px;
            left: 24px; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_modalChatHeader__3yxRG .chatModal_createChat__2breN {
            width: 105px;
            height: 36px;
            position: absolute;
            font-size: 14px;
            line-height: 21px;
            border: 0;
            border-radius: 20.5px;
            color: #EF4639;
            background-color: #fff;
            top: 16px;
            right: 16px; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_singleChatBody__2V2DB {
          margin: 0 30px;
          padding: 50px 0;
          min-height: 173px;
          max-height: 458px;
          display: flex;
          justify-content: center;
          flex-direction: column; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_singleChatBody__2V2DB label {
            margin-bottom: 3px; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_singleChatBody__2V2DB .chatModal_inputContainer__2ECfY {
            position: relative;
            width: 100%; }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_singleChatBody__2V2DB .chatModal_inputContainer__2ECfY .chatModal_searchIcon__2V6PO {
              position: absolute;
              padding: 13.72px 12.72px;
              color: #E7EAF1; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_singleChatBody__2V2DB.chatModal_changePadding__1QQEX {
            padding-bottom: 35px; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 {
          padding: 15px 0;
          min-height: 458px;
          max-height: 781px;
          width: 600px;
          display: flex;
          justify-content: center;
          flex-direction: column; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 > div:not(.chatModal_selectedMembersContainer__33oa8) {
            margin-left: 30px;
            margin-right: 30px; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 {
            display: flex;
            justify-content: space-between; }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_titleContainer__2MhX9 {
              width: 50%;
              align-self: flex-end; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_titleContainer__2MhX9 label {
                margin-bottom: 3px; }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_uploadPhotoContainer__HL1M7 {
              display: grid;
              width: 50%;
              grid-template-columns: 50% 50%;
              justify-items: center; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_uploadPhotoContainer__HL1M7 label:first-child {
                margin-top: 17px;
                cursor: pointer;
                justify-self: end; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_uploadPhotoContainer__HL1M7 .chatModal_uploadAvatarArea__1rh9y {
                margin-bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #b9b9b9;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                background: transparent;
                cursor: pointer;
                overflow: hidden; }
                .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_uploadPhotoContainer__HL1M7 .chatModal_uploadAvatarArea__1rh9y i {
                  font-size: 30px;
                  color: #E7EAF1; }
                .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_uploadPhotoContainer__HL1M7 .chatModal_uploadAvatarArea__1rh9y img {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                  object-fit: cover; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_uploadPhotoContainer__HL1M7 .chatModal_uploadPhotoInput__2Iyd- {
                display: none; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_descriptionContainer__isoIu {
            margin-top: 30px; }
            @media (max-height: 1080px) {
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_descriptionContainer__isoIu {
                margin-top: 10px; } }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_descriptionContainer__isoIu label {
              margin-bottom: 3px; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_findUsersContainer__2cwyG {
            position: relative;
            margin-top: 30px; }
            @media (max-height: 1080px) {
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_findUsersContainer__2cwyG {
                margin-top: 10px; } }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_findUsersContainer__2cwyG label {
              margin-bottom: 3px; }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_findUsersContainer__2cwyG .chatModal_inputContainer__2ECfY {
              position: relative; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_findUsersContainer__2cwyG .chatModal_inputContainer__2ECfY .chatModal_searchIcon__2V6PO {
                position: absolute;
                padding: 13.72px 12.72px;
                color: #E7EAF1; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_findUsersContainer__2cwyG .chatModal_inputContainer__2ECfY .chatModal_clearInputButton__1w8cI {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 43px;
                width: 43px;
                font-size: 18px;
                font-weight: 100;
                position: absolute;
                right: 0;
                top: 0;
                visibility: hidden; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_findUsersContainer__2cwyG .chatModal_inputContainer__2ECfY .chatModal_clearInputButton__1w8cI.chatModal_visible__3a2LF {
                visibility: visible; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 {
            margin-top: 20px;
            height: 127px;
            width: 100%;
            background-color: #E7EAF1; }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 p {
              padding: 5px 30px;
              width: 100%;
              height: 30px;
              background-color: #fff;
              display: flex;
              justify-content: space-between; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 p span {
                font-size: 14px;
                line-height: 21px;
                color: #989da1; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 p button {
                border: 0;
                font-size: 14px;
                line-height: 21px;
                color: #ef4639;
                background-color: #fff; }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 .chatModal_overflowUsers__2RdBO {
              margin: 0 30px;
              display: flex;
              overflow: auto;
              overflow-y: hidden;
              scrollbar-width: thin; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 .chatModal_overflowUsers__2RdBO::-webkit-scrollbar {
                width: 0px;
                height: 8px; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 .chatModal_overflowUsers__2RdBO::-webkit-scrollbar-button {
                width: 0px;
                height: 8px; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 .chatModal_overflowUsers__2RdBO::-webkit-resizer {
                width: 0px;
                height: 8px; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 .chatModal_overflowUsers__2RdBO .chatModal_selectedUserContainer__2wkte {
                margin: 7px 12px 7px 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding-right: 8px;
                padding-top: 6px;
                position: relative;
                height: 100%;
                width: 68px; }
                .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 .chatModal_overflowUsers__2RdBO .chatModal_selectedUserContainer__2wkte:only-child {
                  margin-right: 0; }
                .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 .chatModal_overflowUsers__2RdBO .chatModal_selectedUserContainer__2wkte .chatModal_userImageContainer__ZmvBZ {
                  width: 60px;
                  height: 60px;
                  border-radius: 50%; }
                  .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 .chatModal_overflowUsers__2RdBO .chatModal_selectedUserContainer__2wkte .chatModal_userImageContainer__ZmvBZ img {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%; }
                .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 .chatModal_overflowUsers__2RdBO .chatModal_selectedUserContainer__2wkte .chatModal_selectedUserName__2aRz4 {
                  width: 60px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: center;
                  margin-top: 5px;
                  font-size: 12px;
                  line-height: 18px;
                  color: #605655; }
                .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembersContainer__33oa8 .chatModal_overflowUsers__2RdBO .chatModal_selectedUserContainer__2wkte .chatModal_deleteFromSelectedButton__1Iyim {
                  position: absolute;
                  top: 0;
                  right: 0;
                  color: #fff;
                  width: 27px;
                  height: 27px;
                  border: 2px solid #fff;
                  border-radius: 50%;
                  background-color: #ef4639; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_selectedMembers__23YEY {
            margin-top: 10px;
            font-size: 14px;
            line-height: 21px;
            color: #989da1; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_createGroupSubmitContainer__LEBOH {
            margin-top: 24px;
            display: flex;
            justify-content: flex-end; }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_createGroupSubmitContainer__LEBOH .chatModal_submit__2WCkU {
              width: 105px;
              height: 35px;
              background-color: #ef4639;
              border: 0;
              border-radius: 20.5px;
              color: #fff;
              font-size: 14px; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_usersBlockTitle__156eU,
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_usersBlockErrorTitle__20BPI {
          margin-top: 10px;
          font-size: 14px;
          line-height: 21px;
          color: #989da1; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_usersBlockErrorTitle__20BPI,
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_usersBlockErrorImg__3cw3I {
          color: red;
          font-size: 16px; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_usersBlockErrorImg__3cw3I {
          grid-column-start: 1;
          grid-column-end: 3; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS {
          margin-top: 5px;
          padding: 10px 0;
          max-height: 295px;
          overflow-x: hidden;
          overflow-y: auto; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS::-webkit-scrollbar {
            width: 6px; }
          @media (max-height: 1080px) {
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS {
              max-height: 200px; } }
          @media (max-height: 575px) {
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS {
              max-height: 155px; } }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt {
            cursor: pointer;
            padding: 10px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e7eaf1; }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt:hover {
              background: #E7EAF1; }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt:only-child {
              margin-bottom: 0;
              padding-bottom: 0;
              border-bottom: none; }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt .chatModal_userImage__17rKg {
              margin-right: 10px;
              width: 35px;
              min-width: 35px;
              height: 35px;
              border-radius: 50%;
              background: transparent;
              -webkit-transition: background 0.3s ease-out;
              transition: background 0.3s ease-out;
              border: 1px solid #b9b9b9;
              overflow: hidden; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt .chatModal_userImage__17rKg img {
                position: relative;
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                opacity: 1;
                -webkit-transition: opacity 0.3s ease-out;
                transition: opacity 0.3s ease-out;
                font-size: 0;
                overflow: hidden; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt .chatModal_userImage__17rKg img::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url("/frontend/images/no-image.jpg") no-repeat, center;
                background-size: contain; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt .chatModal_userImage__17rKg i {
                display: none;
                opacity: 0; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt .chatModal_userImage__17rKg.chatModal_selectedUser__1K-LA {
                background: #EF4639;
                -webkit-transition: background 0.3s ease-in;
                transition: background 0.3s ease-in;
                display: flex;
                align-items: center;
                justify-content: center; }
                .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt .chatModal_userImage__17rKg.chatModal_selectedUser__1K-LA img {
                  width: 0;
                  height: 0;
                  opacity: 0; }
                .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt .chatModal_userImage__17rKg.chatModal_selectedUser__1K-LA i {
                  font-size: 18px;
                  opacity: 1;
                  color: #fff;
                  display: inline;
                  display: initial;
                  -webkit-transition: opacity 0.3s ease;
                  transition: opacity 0.3s ease; }
            .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt .chatModal_userNameContainer__25aOA {
              display: flex;
              flex-direction: column; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt .chatModal_userNameContainer__25aOA h3 {
                font-size: 18px;
                line-height: 27px;
                color: #605655; }
              .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_userListContainer__3niRS .chatModal_userContainer__1XICt .chatModal_userNameContainer__25aOA span {
                font-size: 14px;
                line-height: 21px;
                color: #989da1; }

.chatModal_searchUserInput__253Mv {
  width: 100%;
  height: 43px;
  padding: 0 35px;
  background: #fff;
  border: 1px solid #e7eaf1;
  border-radius: 3px; }
  .chatModal_searchUserInput__253Mv::-webkit-input-placeholder {
    font-size: 14px;
    color: #989DA1;
    opacity: 0.5; }
  .chatModal_searchUserInput__253Mv::-moz-placeholder {
    font-size: 14px;
    color: #989DA1;
    opacity: 0.5; }
  .chatModal_searchUserInput__253Mv::-ms-input-placeholder {
    font-size: 14px;
    color: #989DA1;
    opacity: 0.5; }
  .chatModal_searchUserInput__253Mv::placeholder {
    font-size: 14px;
    color: #989DA1;
    opacity: 0.5; }

label {
  font-size: 18px;
  line-height: 27px;
  color: #605655; }

.chatModal_closeButtonContainer__20rh0 {
  position: absolute;
  top: 50%;
  right: 8px;
  width: 21px;
  height: 21px;
  background: #FAFAFA;
  border: 1px solid #E7EAF1;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  visibility: hidden;
  cursor: pointer; }
  .chatModal_closeButtonContainer__20rh0:hover {
    background: #E7EAF1; }

.chatModal_closeButtonStickOne__2rNph, .chatModal_closeButtonStickTwo__oTjbr {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 9px;
  background: #605655;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%; }

.chatModal_closeButtonStickOne__2rNph {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg); }

.chatModal_closeButtonStickTwo__oTjbr {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg); }

@media (max-width: 575.98px) {
  .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e {
    overflow: unset;
    left: unset;
    bottom: unset; }
    .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe {
      min-width: 375px;
      max-height: 790px; }
      .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_modalChatHeader__3yxRG {
        height: 58px;
        justify-content: flex-start; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_modalChatHeader__3yxRG .chatModal_modalTitle__3QVFV {
          margin-left: 70px;
          font-size: 19px; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_modalChatHeader__3yxRG i {
          top: 18px;
          left: 24px; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_modalChatHeader__3yxRG .chatModal_createChat__2breN {
          width: 95px;
          top: 11px;
          right: 11px; }
      .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 {
        padding: 10px 0 10px 0;
        width: 100%; }
      .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 {
        min-height: 400px;
        margin: 0 10px; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 > div:not(.chatModal_selectedMembersContainer__33oa8) {
          margin-left: 10px;
          margin-right: 10px; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_titleContainer__2MhX9 {
          width: 60%; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_uploadPhotoContainer__HL1M7 label:first-child {
          display: none; }
        .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_uploadPhotoContainer__HL1M7 .chatModal_uploadAvatarArea__1rh9y {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background: transparent; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_uploadPhotoContainer__HL1M7 .chatModal_uploadAvatarArea__1rh9y i {
            font-size: 30px;
            color: #E7EAF1; }
          .chatModal_overlay__18wqo .chatModal_modalContainer__1cP3e .chatModal_modalDialog__1Jnoe .chatModal_modalContent__35A77 .chatModal_groupChatBody__2bbT7 .chatModal_titleAndPhotoContainer__8QVu9 .chatModal_uploadPhotoContainer__HL1M7 .chatModal_uploadAvatarArea__1rh9y img {
            width: 80px;
            height: 80px; } }

.chatComponent_chatScreen__1OZjK {
  height: 100%; }
  .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX {
    border-bottom: 1px solid #e7eaf1;
    padding: 25px 30px;
    height: 114px;
    display: flex;
    justify-content: space-between; }
    .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_backToChatList__-h0Oi {
      display: none; }
    .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb {
      display: flex; }
      .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb img {
        position: relative;
        margin-right: 17px;
        width: 64px;
        min-width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: lightgrey;
        border: 1px solid #b9b9b9;
        font-size: 0;
        overflow: hidden; }
      .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb img::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("/public/frontend/images/no-image.jpg") no-repeat, center;
        background-size: contain; }
      .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb .chatComponent_nameContainer__2YfbB {
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb .chatComponent_nameContainer__2YfbB .chatComponent_chatName__3bsb4 {
          color: #000;
          font-size: 18px;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis; }
        .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb .chatComponent_nameContainer__2YfbB .chatComponent_chatMember__2yOzQ {
          color: #989DA1;
          font-size: 14px;
          line-height: 21px; }
      .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb .chatComponent_chatAvatarContainer__25KQ2 {
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 65px;
        background-color: lightgray;
        border-radius: 50%;
        margin-right: 12px; }
        .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb .chatComponent_chatAvatarContainer__25KQ2 i {
          font-size: 42px; }
    .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_chatMenu__11EUk {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_chatMenu__11EUk:hover > i {
        color: #697578; }
      .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_chatMenu__11EUk i {
        font-size: 22px;
        color: #989DA1; }
      .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_chatMenu__11EUk .chatComponent_openChatMenu__QndQs {
        width: 130px;
        top: 55px;
        left: -19px; }

.chatComponent_sendMessagePanel__3-WZo {
  width: 100%;
  min-height: 75px;
  position: relative;
  border-top: 1px solid #E7EAF1;
  bottom: 0;
  background-color: #fff; }
  .chatComponent_sendMessagePanel__3-WZo .chatComponent_selectedImagesSparks__CHcsi {
    max-width: 100%;
    margin: 5px 15px;
    overflow: auto;
    display: flex; }
    .chatComponent_sendMessagePanel__3-WZo .chatComponent_selectedImagesSparks__CHcsi::-webkit-scrollbar, .chatComponent_sendMessagePanel__3-WZo .chatComponent_selectedImagesSparks__CHcsi::-webkit-scrollbar-button, .chatComponent_sendMessagePanel__3-WZo .chatComponent_selectedImagesSparks__CHcsi::-webkit-resizer {
      height: 8px;
      width: 4px; }
    .chatComponent_sendMessagePanel__3-WZo .chatComponent_selectedImagesSparks__CHcsi div {
      display: inline-block;
      position: relative; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_selectedImagesSparks__CHcsi div img {
        width: 120px;
        height: 120px;
        margin: 15px;
        object-fit: cover;
        border: 1px solid grey; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_selectedImagesSparks__CHcsi div i {
        position: absolute;
        right: 5px;
        color: #e74437;
        background: #fff;
        font-size: 20px;
        top: 7px;
        text-shadow: 0 0 black;
        border-radius: 50%;
        padding: 0;
        cursor: pointer; }
  .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 17px 20px 17px 20px; }
    .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_textAreaContainer__37VLZ {
      width: 100%;
      margin-right: 10px; }
    .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_sendMessInput__J5tSp {
      width: 100%;
      height: 36px;
      padding: 6px 10px;
      background-color: #F2F2F2;
      border: 1px solid #E7EAF1;
      resize: none;
      border-radius: 17.5px;
      color: #605655;
      font-size: 15px;
      line-height: 22px;
      outline: none; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_sendMessInput__J5tSp::-webkit-scrollbar {
        width: 6px;
        height: 0; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_sendMessInput__J5tSp::-webkit-input-placeholder {
        color: #989DA1;
        font-size: 15px;
        line-height: 22px; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_sendMessInput__J5tSp::-moz-placeholder {
        color: #989DA1;
        font-size: 15px;
        line-height: 22px; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_sendMessInput__J5tSp::-ms-input-placeholder {
        color: #989DA1;
        font-size: 15px;
        line-height: 22px; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_sendMessInput__J5tSp::placeholder {
        color: #989DA1;
        font-size: 15px;
        line-height: 22px; }
    .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_erroredInput__YTtmg {
      border-color: red; }
    .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_twoLinedMessInput__1yvQh {
      height: 58px; }
    .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_charLimitError__3xnV7 {
      font-size: 14px;
      line-height: 21px;
      color: red;
      position: absolute;
      bottom: 83px;
      left: 30px; }
    .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe {
      display: flex;
      justify-content: flex-end; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiModalContainer__h6mnz {
        position: absolute;
        bottom: 92px;
        right: 18px; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiModalContainer__h6mnz::after {
          content: '';
          position: absolute;
          bottom: -5px;
          left: 50%;
          border: 6px solid transparent;
          border-bottom-color: #ffffff;
          border-right-color: #ffffff;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg); }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_addPhoto__goTs_, .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiButton__CkeMn {
        margin-right: 10px; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_addPhoto__goTs_ label, .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiButton__CkeMn label {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: #fff; }
          .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_addPhoto__goTs_ label i, .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiButton__CkeMn label i {
            color: #EF4639; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_addPhoto__goTs_ .chatComponent_uploadPhotoInput__ayK6q, .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiButton__CkeMn .chatComponent_uploadPhotoInput__ayK6q {
          display: none; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_addPhoto__goTs_ label:hover {
        background: #EF4639; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_addPhoto__goTs_ label:hover path {
        fill: #ffffff; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_addPhoto__goTs_.chatComponent_disabled__2Fxxk label path {
        fill: #989DA1; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_addPhoto__goTs_.chatComponent_disabled__2Fxxk label:hover {
        background: none;
        cursor: default; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiButton__CkeMn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #fff;
        -webkit-transition: background 0.2s ease-out;
        transition: background 0.2s ease-out; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiButton__CkeMn:hover {
          background-color: #EF4639; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiButton__CkeMn:hover > i {
          color: #FFFFFF; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiButton__CkeMn i {
          font-size: 20px;
          color: #EF4639; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiButton__CkeMn.chatComponent_active__3O6OT {
          background: #EF4639; }
          .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiButton__CkeMn.chatComponent_active__3O6OT i {
            color: #FFFFFF; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_messSend__YMWBr {
        width: 105px;
        height: 35px;
        border: 0;
        border-radius: 20.5px;
        color: #fff;
        background-color: #EF4639; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_messSend__YMWBr:hover {
          background-color: #FF5B4F; }

.chatComponent_userChatContainer__21F3d {
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 64px - 114px - 99px);
  overflow-anchor: auto;
  display: flex;
  flex-flow: column nowrap;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text; }
  .chatComponent_userChatContainer__21F3d::-webkit-scrollbar-button,
  .chatComponent_userChatContainer__21F3d .chatComponent_userChatContainer__21F3d::-webkit-resizer {
    height: 0; }
  .chatComponent_userChatContainer__21F3d > :first-child {
    margin-top: auto !important; }
  .chatComponent_userChatContainer__21F3d.chatComponent_loadedImg__3h2SP {
    height: calc(100vh - 64px - 97px - 250px); }
  .chatComponent_userChatContainer__21F3d .chatComponent_conversationDay__1lwvw {
    margin: 36px 0;
    display: flex;
    justify-content: center;
    font-size: 11px;
    line-height: 16px;
    color: #989DA1; }
  .chatComponent_userChatContainer__21F3d .chatComponent_unreadMessagesBelow__14rOS {
    display: none;
    margin: 22px 28px 22px 0;
    justify-content: space-between; }
    .chatComponent_userChatContainer__21F3d .chatComponent_unreadMessagesBelow__14rOS::before {
      content: "";
      width: 40%;
      margin-bottom: 10px;
      border-bottom: 1px solid #EF4639; }
    .chatComponent_userChatContainer__21F3d .chatComponent_unreadMessagesBelow__14rOS::after {
      content: "";
      width: 40%;
      margin-bottom: 10px;
      border-bottom: 1px solid #EF4639; }
    .chatComponent_userChatContainer__21F3d .chatComponent_unreadMessagesBelow__14rOS > span {
      font-family: Poppins;
      font-size: 12px;
      line-height: 18px;
      padding: 0 11px;
      text-align: center;
      color: #EF4639; }
  .chatComponent_userChatContainer__21F3d .chatComponent_showUnreadMessagesBelow__1yapt {
    display: flex; }
  .chatComponent_userChatContainer__21F3d .chatComponent_hideConversationDate__RuTsy {
    display: none; }
  .chatComponent_userChatContainer__21F3d .chatComponent_messageImageBlock__2uWUf {
    min-height: 279px;
    min-width: 279px; }
    .chatComponent_userChatContainer__21F3d .chatComponent_messageImageBlock__2uWUf div div div {
      margin: 5px 0px 2px 0px; }
      .chatComponent_userChatContainer__21F3d .chatComponent_messageImageBlock__2uWUf div div div img {
        padding: 7px;
        border: 1px solid #e7eaf1;
        border-radius: 25px 25px 7.5px 7.5px;
        display: inline-block;
        object-fit: contain; }
  .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV {
    display: flex;
    margin-bottom: 2px;
    margin-top: 12px; }
    .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV .chatComponent_userMessageContainer__25YaQ {
      max-width: 70%;
      display: flex; }
      .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV .chatComponent_userMessageContainer__25YaQ .chatComponent_messageText__12PmY {
        min-height: 35px;
        padding: 5px 20px 7px;
        border: 1px solid #e7eaf1;
        border-radius: 25px 25px 7.5px 7.5px;
        font-size: 15px;
        line-height: 22px;
        color: #605655;
        overflow-wrap: anywhere; }
    .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV .chatComponent_scrollBottom__2ObQk {
      position: absolute;
      right: 15px;
      bottom: 15px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #F2F2F2;
      border: 1px solid #E7EAF1;
      cursor: pointer;
      -webkit-transition: all .350s linear;
      transition: all .350s linear; }
      .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV .chatComponent_scrollBottom__2ObQk::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        left: 11px;
        top: 10px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        border-right: 2px solid #989DA1;
        border-bottom: 2px solid #989DA1; }
    .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV .chatComponent_scrollBottomAppear__17zF5 {
      bottom: 125px; }
    .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_myMessage__15Vy4 {
      margin-right: 25px;
      flex-direction: row-reverse; }
      .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_myMessage__15Vy4 .chatComponent_userAvatarContainer__3w5G5 {
        display: none; }
      .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_myMessage__15Vy4 .chatComponent_userMessageContainer__25YaQ {
        flex-direction: column;
        width: 100%; }
        .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_myMessage__15Vy4 .chatComponent_userMessageContainer__25YaQ .chatComponent_messageText__12PmY {
          border-bottom-left-radius: 25px;
          background: #e7eaf1;
          float: right;
          display: inline-block; }
        .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_myMessage__15Vy4 .chatComponent_userMessageContainer__25YaQ .chatComponent_messageImageBlock__2uWUf div div div {
          justify-content: flex-end; }
          .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_myMessage__15Vy4 .chatComponent_userMessageContainer__25YaQ .chatComponent_messageImageBlock__2uWUf div div div img {
            float: right; }
      .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_myMessage__15Vy4 .chatComponent_displayTime__iQAEb {
        justify-content: flex-end;
        margin: 2px 0px 0px 0px; }
    .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_friendMessage__23Rfd {
      flex-direction: row; }
      .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_friendMessage__23Rfd .chatComponent_userAvatarContainer__3w5G5 {
        margin-right: 10px; }
      .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_friendMessage__23Rfd .chatComponent_userMessageContainer__25YaQ {
        flex-direction: column;
        width: 100%; }
        .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_friendMessage__23Rfd .chatComponent_userMessageContainer__25YaQ .chatComponent_messageText__12PmY {
          border-bottom-right-radius: 25px;
          display: inline-block;
          overflow-wrap: anywhere; }
      .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_friendMessage__23Rfd .chatComponent_displayTime__iQAEb {
        margin: 2px 0px 0px 0px; }
    .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV .chatComponent_userAvatarContainer__3w5G5 {
      width: 35px;
      height: 35px;
      border-radius: 50%; }
      .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV .chatComponent_userAvatarContainer__3w5G5 img:not(.chatComponent_showSideAvatar__1_GAY) {
        display: none; }
    .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV .chatComponent_displayTime__iQAEb {
      min-width: 60px;
      display: flex;
      align-items: center;
      font-size: 11px;
      line-height: 16px;
      color: #989da1; }
  .chatComponent_userChatContainer__21F3d .chatComponent_my__LaxA5 + .chatComponent_my__LaxA5 .chatComponent_messageText__12PmY {
    border-top-right-radius: 7.5px; }
  .chatComponent_userChatContainer__21F3d .chatComponent_unread__24Yv9 .chatComponent_messageText__12PmY {
    font-weight: bold; }
  .chatComponent_userChatContainer__21F3d .chatComponent_his__3JmZC + .chatComponent_his__3JmZC .chatComponent_messageText__12PmY {
    border-top-left-radius: 7.5px; }
  .chatComponent_userChatContainer__21F3d .chatComponent_showSideAvatar__1_GAY {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 0;
    border: 1px solid #b9b9b9;
    overflow: hidden; }
  .chatComponent_userChatContainer__21F3d .chatComponent_showSideAvatar__1_GAY::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("/public/frontend/images/no-image.jpg") no-repeat, center;
    background-size: contain; }
  .chatComponent_userChatContainer__21F3d .chatComponent_chatBubbleStop__2Wcxs .chatComponent_userMessageContainer__25YaQ {
    flex-direction: column !important;
    width: 100%; }
    .chatComponent_userChatContainer__21F3d .chatComponent_chatBubbleStop__2Wcxs .chatComponent_userMessageContainer__25YaQ .chatComponent_messageText__12PmY {
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px; }
  .chatComponent_userChatContainer__21F3d .chatComponent_chatBubbleStop__2Wcxs.chatComponent_myMessage__15Vy4 .chatComponent_userMessageContainer__25YaQ .chatComponent_displayTime__iQAEb {
    justify-content: flex-end;
    margin: 2px 0 0 0; }
  .chatComponent_userChatContainer__21F3d .chatComponent_chatBubbleStop__2Wcxs.chatComponent_friendMessage__23Rfd .chatComponent_userMessageContainer__25YaQ .chatComponent_displayTime__iQAEb {
    justify-content: flex-start;
    margin: 2px 0 0 0; }
  .chatComponent_userChatContainer__21F3d .chatComponent_myMessage__15Vy4 .chatComponent_displayTime__iQAEb {
    margin-right: 10px; }
  .chatComponent_userChatContainer__21F3d .chatComponent_friendMessage__23Rfd .chatComponent_displayTime__iQAEb {
    margin-left: 10px; }
  .chatComponent_userChatContainer__21F3d .chatComponent_noActiveChat__bwq3D {
    align-self: center; }

.chatComponent_leaveChatButton__1jQe9 {
  color: #EF4639 !important; }

@media (max-width: 575.98px) {
  .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX {
    padding: 15px 20px;
    height: 98px;
    background: #fff; }
    .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_backToChatList__-h0Oi {
      margin-right: 20px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_backToChatList__-h0Oi i {
        font-size: 24px;
        color: #989DA1; }
    .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb {
      width: 100%;
      display: flex;
      align-items: center; }
      .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb img {
        margin-right: 17px;
        width: 48px;
        min-width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: lightgrey; }
      .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb .chatComponent_nameContainer__2YfbB {
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb .chatComponent_nameContainer__2YfbB .chatComponent_chatName__3bsb4 {
          color: #000;
          font-size: 15px;
          max-width: 200px; }
        .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_avatarNameContainer__2DxQb .chatComponent_nameContainer__2YfbB .chatComponent_chatMember__2yOzQ {
          color: #989DA1;
          font-size: 14px;
          line-height: 21px; }
    .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_chatMenu__11EUk .chatComponent_openChatMenu__QndQs {
      width: 130px;
      top: 55px;
      left: -107px; }
      .chatComponent_chatScreen__1OZjK .chatComponent_chatInfoContainer__3azQX .chatComponent_chatMenu__11EUk .chatComponent_openChatMenu__QndQs::before {
        left: 100px; }
  .chatComponent_sendMessagePanel__3-WZo {
    width: 100%;
    min-height: 75px;
    max-height: 100px;
    position: relative;
    border-top: 1px solid #E7EAF1;
    bottom: 0;
    background-color: #fff; }
    .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 17px 20px 17px 20px; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_textAreaContainer__37VLZ {
        width: 100%;
        margin-right: 10px; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_sendMessInput__J5tSp {
        width: 100%;
        min-height: 36px;
        resize: vertical;
        max-height: 58px;
        padding: 6px 10px;
        background-color: #F2F2F2;
        border: 1px solid #E7EAF1;
        resize: none;
        border-radius: 17.5px;
        color: #605655;
        font-size: 15px;
        line-height: 22px; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_sendMessInput__J5tSp::-webkit-input-placeholder {
          color: #989DA1;
          font-size: 15px;
          line-height: 22px; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_sendMessInput__J5tSp::-moz-placeholder {
          color: #989DA1;
          font-size: 15px;
          line-height: 22px; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_sendMessInput__J5tSp::-ms-input-placeholder {
          color: #989DA1;
          font-size: 15px;
          line-height: 22px; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_sendMessInput__J5tSp::placeholder {
          color: #989DA1;
          font-size: 15px;
          line-height: 22px; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_messSend__YMWBr {
        padding-top: 3px;
        width: 35px;
        height: 35px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: transparent;
        font-size: 0; }
        .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_messSend__YMWBr i {
          font-size: 14px;
          color: #fff;
          display: inline;
          display: initial; }
      .chatComponent_sendMessagePanel__3-WZo .chatComponent_sendMessMain__3n3Kz .chatComponent_submitContainer__3rPNe .chatComponent_emojiModalContainer__h6mnz::after {
        left: 75%; }
  .chatComponent_userChatContainer__21F3d {
    padding: 10px;
    height: calc(100vh - 64px - 97px - 99px); }
    .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV .chatComponent_userMessageContainer__25YaQ .chatComponent_messageText__12PmY {
      padding: 5px 15px 7px; }
    .chatComponent_userChatContainer__21F3d .chatComponent_chat__1bEYV.chatComponent_myMessage__15Vy4 .chatComponent_userAvatarContainer__3w5G5 {
      margin-left: 5px; }
    .chatComponent_userChatContainer__21F3d .chatComponent_myMessage__15Vy4 .chatComponent_displayTime__iQAEb {
      margin-right: 5px; } }

.chatComponent_hiddenBlock__nt2mw {
  opacity: 1; }

.chatEngine_Chat__3VSAQ .chatEngine_chatRow__zk50Y {
  outline: 1px solid #E7EAF1;
  height: calc(100vh - 64px); }
  .chatEngine_Chat__3VSAQ .chatEngine_chatRow__zk50Y .chatEngine_chatColSm3__2uABX {
    background-color: #fafafa;
    height: hw;
    padding-top: 30px; }
    .chatEngine_Chat__3VSAQ .chatEngine_chatRow__zk50Y .chatEngine_chatColSm3__2uABX.chatEngine_hideChatList__2hKG9 {
      display: none; }
  .chatEngine_Chat__3VSAQ .chatEngine_chatRow__zk50Y .chatEngine_chatColSm9__3XDB2 {
    outline: 1px solid #E7EAF1;
    height: hw; }
    .chatEngine_Chat__3VSAQ .chatEngine_chatRow__zk50Y .chatEngine_chatColSm9__3XDB2 h4 {
      margin: 40% auto;
      text-align: center;
      font-weight: 100;
      font-size: 18px;
      color: #605655;
      font-family: Poppins; }

.chatEngine_ChatEngine__3kmbp .chatEngine_content-part__3YHBl {
  margin-top: 64px !important; }

@media (min-width: 576px) and (max-width: 767.98px) {
  .chatEngine_Chat__3VSAQ .chatEngine_chatRow__zk50Y .chatEngine_chatColSm3__2uABX {
    padding-top: 15px; } }

@media (max-width: 575.98px) {
  .chatEngine_Chat__3VSAQ .chatEngine_chatRow__zk50Y .chatEngine_chatColSm9__3XDB2 {
    outline: none; } }

.chatEmptyMessage_chatEmptyContainer__2OQWR {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-size: 18px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chatEmptyMessage_chatEmptyTitle__1XFFY {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 27px; }

.chatEmptyMessage_chatButtonsContainer__3XtQS {
  display: flex;
  flex-direction: row;
  margin: 20px; }

.chatEmptyMessage_chatEmptyOr__Spvrv {
  margin: 0 18px;
  display: flex;
  align-items: center; }

.chatEmptyMessage_chatEmptyCreateButton__3CnP8 {
  width: 174px;
  height: 35px;
  font-size: 14px;
  border-radius: 17.5px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background: #EF4639;
  color: #FFFFFF;
  cursor: pointer; }

