.title {
    font-size: 20px;
    color: #697578;
    margin-bottom: 40px;
    text-align: center;
}

.text {
    text-align: center;
}
