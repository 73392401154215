.dropdownChosen {
    width: 100%;
    height: 32px;
    background: #ECECEC;
    border: 1px solid #ECECEC;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    justify-content: space-between;
}

.dropdownText {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdownTextName {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #697578;
    text-transform: uppercase;
    width: 112px;
}

.dropdownTextPrice {
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #212529;
    margin-left: 36px;
    width: 116px;

    span {
      font-weight: 400;
    }
}

.dropdownArrow {
    padding: 14px;
}


.dropdownTitle {
    margin-top: 5px;

}

.dropdownPer {
    font-size: 12px;
    text-transform: capitalize;
}

.dropdownTitle {
    padding-left: 9px;
    background: #ECECEC;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    height: 32px;
    color: #697578;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.dropdown {
    position: absolute;
    z-index: 2;
    width: 415px;
}

.dropdownPlan {
    
    display: flex;
    background: #ECECEC;
    height: 32px;
    padding-left: 20px;
    display: flex;
    align-items: center;

    &:nth-child(odd) {
        background: #D9D9D9;
    }

    &:hover {
        background:  #EF4639;

       .dropdownTextName, .dropdownTextPrice, .dropDownTrial {
            color: white;
       } 
    }
}

.dropDownTrial {
    margin-left: 10px;
    font-size: 13px;
    line-height: 16px;
}

@media only screen and (max-width: 1200px) {
    .dropdown {
        width: 460px;
    }
  }