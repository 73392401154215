.container {
    margin-top: -30px;
    width: 100%;
    position: relative;
    z-index: 1;
    margin-bottom: 40px;
}

.progressBar, .progressBarTrial {
    counter-reset: step;
}

.progressBarTrial li {
    float: left;
    //width: 50%; //here
    position: relative;
    font-size: 12px;
    font-weight: 500;
    color: #697578;
    line-height: 14px;
    text-align: center;
}

.progressBarTrial li:first-child {
    width: 60%;
}

.progressBarTrial li:last-child {
    width: 27%;
}

.progressBar li {
    float: left;
    width: 25%; //here
    position: relative;
    font-size: 12px;
    color: #697578;
    line-height: 14px;
    text-align: center;
    font-weight: 500;
}

.progressBar li:before, .progressBarTrial li:before {
    content: counter(step);
    counter-increment: step;
    width: 19px;
    height: 19px;
    border: 1px solid #ececec;
    display: block;
    margin: 0 auto 5px auto;
    border-radius: 50%;
    line-height: 18px;
    background: #ececec;
    color: #697578;
    text-align: center;
    font-weight: 500;
}

.progressBar li:first-child:before, .progressBarTrial li:first-child:before {
    border: 1px solid #ef4639;
    background: #ef4639;
    color: #ffffff;
}

.progressBar li:after, .progressBarTrial li:after {
    content: '';
    position: absolute;
    width:100%;
    height: 1px;
    background: #ececec;
    top: 10px;
    left: -50%;
    z-index: -1;
}

.progressBarTrial li:after {
    width:145%;
    left: -100%;
}

.progressBar li.active, .progressBarTrial li:after{
    color: #697578;
}

.progressBar li.active::before, .progressBarTrial li.active::before{
    transition: background-color 0.5s ease;
    background: #fff;
    border-color: #ef4639;
    color: #ef4639;
}

.progressBar li:first-child:after, .progressBarTrial li:first-child:after {
    display: none;
}

.progressBar li.current, li.active {
    color: #ef4639;
}

.progressBarTrial li.current, li.active {
    color: #ef4639;
}

.progressBar li.current::after, li.active::after {
    background: #ef4639;
}
.progressBarTrial li.current::after, li.active::after {
    background: #ef4639;
}

.progressBar li.current::before, .progressBarTrial li.current::before {
    border-color: #ef4639;
    background: #ef4639;
    color: #ffffff;
}

.progressBar li.active::before, .progressBarTrial li.active::before{
    content: url(../../../check-icon-progress.png);
}