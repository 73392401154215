.chatContainer {
  position: static;
  padding: 0 18px;
  padding-right: 0;
  margin-right: 3px;
  height: 55px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .redDot {
    display: none;
  }

  .chatAvatarContainer {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: lightgray;
    border-radius: 50%;
    img {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      font-size: 0;
      border: 1px solid #b9b9b9;
      overflow: hidden;
    }
    img::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("/frontend/images/no-image.jpg") no-repeat, center;
      background-size: contain;
    }
    i {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
    }
  }

  .chatNameMessageInfo {
    margin-left: 10px;
    min-width: 0;
    width: 82%;
    height: 50px;
    display: flex;
    flex-direction: column;
    h3, p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h3 {
      height: 27px;
      font-size: 18px;
      line-height: 27px;
      font-weight: normal;
      color: #605655;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      color: #989DA1;
    }
  }

  .chatMenuContainer {
    // position: absolute;
    // left: 250px;
    width: 14px;
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    cursor: pointer;
    i {
      color: #989DA1;
    }
  }

  &.activeChat {
    background: #EF4639;
    .chatNameMessageInfo {
      h3, p {
        color: #fff;
      }
    }

    .chatMenuContainer {
      i {
        color: #fff;
      }
    }
  }

  &:not(.activeChat):hover {
    background: #E7EAF1;
  }

  .noPhotoIcon {
    color: gray;
  }

  &.pendingChat {
    .chatAvatarContainer {
      min-width: 35px;
      min-height: 35px;
      background: linear-gradient(100deg, #E7EAF1 30%, #f6f7f8 50%, #E7EAF1 70%);
      background-size: 400%;
      animation: loading 1.2s ease-in-out infinite;

      img {
        display: none;
      }
      i {
        display: none;
      }
    }
    .chatNameMessageInfo {
      justify-content: space-evenly;
      h3, p {
        border-radius: 3px;
        color: transparent;
        background: linear-gradient(100deg, #E7EAF1 30%, #f6f7f8 50%, #E7EAF1 70%);
        background-size: 400%;
        animation: loading 1.2s ease-in-out infinite;
      }
      h3 {
        max-width: 140px;
        height: 13px;
      }
      p {
        max-width: 100px;
        height: 10px;
      }
    }
    .chatMenuContainer {
      display: none;
    }
    @keyframes loading {
      0% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0 50%;
      }
    }
  }

  &.unread:not(.pendingChat) {
    padding-left: 0;
    .redDot {
      display: block;
      margin: 0 5px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #EF4639;
    }
    .chatNameMessageInfo {
      h3, p {
        font-weight: 600;
      }
    }
  }
}

.chatMenuContainer .chatOptionMenu {
  display: none;
  width: 140px;
}
