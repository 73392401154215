.headerChatList {
  margin: 0 0 40px 0;
  display: flex;
  justify-content: space-between;
  h4 {
    display: flex;
    align-items: center;
    color: #605655;
    margin: 0;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
  }
  .newChatBtn {
    padding: 0 17px;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 121px;
    height: 35px;
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    border-radius: 17.5px;
    background-color: #ef4639;

    cursor: pointer;
  }
  .newChatBtn > i {
    font-size: 11px;
  }
}

.tabs {
  position: relative;
  margin-top: 30px;
  .tabList {
    padding: 0 15px;
    display: flex;
    justify-content: space-around;
    list-style: none;
  }
}

.newChatBtn:hover .newChatMenu {
  display: flex;
  top: 42px;
  left: 5px;
  &::after {
    content: '';
    position: absolute;
    top: -7px;
    width: 100%;
    height: 7px;
  }
}

.chatList {
  //height: calc(~"100Vh - 64px - 224px");
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  border-radius: 3px;

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar {
    height:0;
    width:6px;
  } 

  &::-webkit-scrollbar-thumb { 
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb{
    background: #D4D9E3;
  }

  &:hover {
  scrollbar-color: #D4D9E3 transparent;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .headerChatList {
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
    h4 {
      text-align: center;
      font-size: 16px;
    }
    .newChatBtn {
      width: 130px;
      justify-content: space-evenly;
      font-size: 14px;
    }
  }
  
  .tabs {
    margin-top: 20px;
  }
}

@media (max-width: 575.98px) {
  .headerChatList {
    margin-bottom: 20px;
    
    h4 {
      text-align: center;
      font-size: 16px;
    }
    .newChatBtn {
      width: 130px;
      justify-content: space-evenly;
      font-size: 14px;
    }
  }

  .tabs {
    margin-top: 20px;
  }

  .newChatBtn:hover .newChatMenu {
    left: auto;
  }
}
