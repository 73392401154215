.container {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    min-height: 450px
  }
  
  .titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      width: 100%;
      margin-top: 48px;
      display: inline-flex;
      align-items: baseline;
      span {
        //flex: 1;
        font-size: 22px;
        color: #ef4639;
        font-weight: 600;
        i {
          cursor: pointer;
        }
      }
      h4 {
        flex: 2;
        font-size: 20px;
        line-height: 30px;
        color: #697578;
        font-weight: 500;
        text-align: center;
      }
    }
    .planDescription {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      width: 194px;
      height: 28px;
      text-align: center;
      font-size: 11px;
      line-height: 14px;
      color: #697578;
    }
  }
  
  .chooseContainer {
    width: 100%;
    margin-top: 53px;
  }

  .questionBlocksContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;

      & > div {
        cursor: pointer;
      }
  }

  .questionBlockRed, .questionBlockWhite {
    width: 330px;
    height: 110px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .questionTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
    text-align: center;
  }

  .questionDescription {
    margin-top: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }

  .questionBlockRed {
    background: #EF4639;

    .questionTitle, .questionDescription {
        color: white;
    }
  }


  .questionBlockWhite {
    margin-top: 20px;
    border: 1px solid #EF4639;
    background: white;
    
    .questionTitle, .questionDescription {
        color: #EF4639;
    }
  }
  
  
  @media only screen and (max-width: 480px) {
    .plansContainer {
      justify-content: space-around;
    }
  }
  

  @media only screen and (max-width: 1200px) {
      h4 {
        margin: 0;
      }
  }