.container {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  .title {
    width: 100%;
    // margin-top: 40px;
    display: inline-flex;
    align-items: center;
    span {
      font-size: 22px;
      color: #ef4639;
      font-weight: 600;
      i {
        cursor: pointer;
      }
    }
    h4 {
      flex: 2;
      font-size: 20px;
      line-height: 30px;
      color: #697578;
      font-weight: 500;
      text-align: center;
    }
  }
}

.plan {
  background: #ECECEC;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 100px;
  margin: 0 5px 20px 5px;
  height: 32px;
  min-width: 240px;
}

.cc {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 4px;
  :global(#nb) {
    min-width: 140px;
  }
  :global(#date) {
    min-width: 43px;
  }
  :global(#cvc) {
    min-width: 43px;
  }
}



.fieldWrapper {
  background: #fcfcfc;
  border: 1px solid #ececec;
  margin: 0px 5px 10px;
  flex-direction: row;
  height: 48px;
  border-radius: 50px;
  padding: 8px 14px;
  text-align: center;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &.hasError {
    border: 1px solid #EF4639;
  }
}

.title {
  font-family: Poppins;
  font-size: 10px;
  line-height: 15px; 
  text-align: left;
  color: #999999;
}

.field {
  height: auto;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  border: none !important;
}

.smallFields {
  display: flex;
}


.cardsError {
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #ef4639;
}

.plan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  :global(.fas) {
    margin-right: 14px;
    color: #697578;
  }
}

.planName {
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #697578;
  text-transform: uppercase;
}

.priceText {
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  color: #212529;
}

.priceSmallText {

}

.cardForm {

}

.payButton {
  padding: 13px 30px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  height: 48px;
  border-radius: 100px;
  border: 0;
  outline: none;
  background-color: #EF4639;
  i {
    margin-left: 10px;
  }
  .buttonLoaderContainer {
    width: 24px;
    height: 24px;
    i {
      margin-top: 5px;
    }
  }
  div {
    margin-left: 10px;
    border: 5px solid #ECECEC; /* Light grey */
    border-top: 5px solid #697578; /* Blue */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
  &:disabled {
    background-color: #ECECEC;
    color: #697578;
    transition: background-color 0.1s ease;
  }
}



.buttonCont {
  display: flex;
  justify-content: center;
}

