.welcomeLayout {
  overflow: auto;
    height: 100vh;
    margin-right: 5px;
}

.bg {
  img {
      position:fixed;
      right:0;
      height:100%;
  }
}

.container {
  min-height: 602px;
  max-width: 1140px;
}

.layout {
  background: #fff;
  box-shadow: 0px 0px 12px #A1A1A1;
  position: relative;
  top: 38px;
  border-radius: 10px;
  overflow: hidden;
  min-height: 350px;
}

.abortRegistration {
  position: absolute;
  right: 25px;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: 0;
  i {
    font-size: 18px;
    color: #697578;
  }
}

.leftBar {
  text-align:center;
  height: 100%;
  padding: 50px 50px 20px 50px;
  background: rgb(239, 70, 57) url("/frontend/images/inner-bg.png");
  background-size: cover;
}

.resetPadding {
  padding: 0;
}

.logo {
  text-align: center;
  margin-bottom: 30px;
  img {
    width: 152px;
    height: 57px;
  }
}

.logoText {
  display: block;
  text-align: center;
  color: #4d7e83;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding-top: 3px;
  margin-bottom: 20px;
}

.topBlock {
  text-align: left;
}

.mainLogo {
  width: 135px;
  height: 51px;
}

.tagLine {
  font-size: 14px;
  display: inline-block;
  padding: 0 0 0 50px;
  line-height: 21px;
  font-weight: 300;
  vertical-align: bottom;
  text-align: left;
  color:#fff
}

.welcome {
  font-size: 72px;
  line-height: 64px;
  text-align: left;
  margin-top: 122px;
  color:#fff;
  font-weight: 300;
}

.advantagesBlock {
  text-align:left;
  margin-top: 30px;

  p {
    font-size: 18px;
    line-height: 24px;
    color:#fff;
    margin-bottom: 10px;
    font-weight: 300;

    span {
      margin: 0 20px;
      font-size: 24px;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.copyrightBlock {
  margin-top: 162px;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  font-weight: 300;
}

.copyrightBlockBottom {
  display: none;
}

.rightBar {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1920px) {}
@media only screen and (max-width: 1440px) {}
@media only screen and (max-width: 1376px) {}
@media only screen and (max-width: 1199px) {
  .container {
    max-width: 480px;
    //margin-top: 20px;
  }

  .topBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tagLine {
    padding: 10px 0 0 0;
    text-align: center;
  }

  .welcome {
    margin-top: 22px;
    text-align: center;
    font-size: 48px;
  }

  .advantagesBlock p span {
    display: block;
    opacity: 0;
  }


  .abortRegistration {
    top: 20px;
  }


  .resetPadding {
    height: 100%;

    .leftBar {
      height: 302px;
      padding: 25px;
    }
  }

  .mainLogo {
    width: 100px;
    height: auto;
  }

  .leftBar {
    p {
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      display: inline;
      padding: 5px 0px 0px 0px;
    }

    .advantagesBlock {
      text-align: center;
      margin-top: 10px;

      p span {
        display: inline-block;
        margin: 0px 5px;
        opacity: 1;
        font-size: 20px;
        position: relative;
        top: 2px;
      }
    }
  }

  .rightBar {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .logoDisplay {
    display: none;
  }

  .logo img {
    max-width: 50%;
  }

  .copyrightBlock {
    display: none;
  }

  .copyrightBlockBottom {
    font-family: Poppins;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #697578;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 480px) {}

@media only screen and (max-width: 375px) {

}

