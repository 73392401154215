.optionMenuContainer {
  display: none;
  padding: 10px 0;
  position: absolute;
  width: 170px;
  z-index: 2;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #E7EAF1;
  background: #fff;
  box-shadow: 0px -10px 34px rgba(0, 0, 0, 0.15);

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 13px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
  }

  .option {
    padding: 5px 20px;
    font-size: 15px;
    line-height: 22px;
    color: #605655;
    cursor: pointer;

    &:hover {
      background-color: #e2e2e2;
    }

  }

  &:hover {
    display: flex;
  }

  &.active {
    display: flex;
  }
}

@media (max-width: 575.98px) {
  .optionMenuContainer {
    &::before {
      top: -10px;
      left: 112px;
    }
  }
}
