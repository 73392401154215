.congratsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75%;
  .congratsTitle {
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: #697578;
    font-weight: 500;
  }
  .toLogin {
    margin-top: 20px;
    width:195px;
    height:48px;
    background:#ef4639;
    border-radius:24px;
    border:0;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
  i {
    margin-left: 11px;
  }
  .congratsTrialInfo {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #697578;
    max-width: 370px;
    margin-top: 10px;
  }
}

