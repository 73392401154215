.planCardContainer {
  width: 32%;
  height: 265px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ececec;
  border-radius: 5px;
  background: #fcfcfc;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
}

.durationContainer {
  border-bottom: 1px solid #ececec;
  font-size: 18px;
  line-height: 18px;
  color: #697578;
  text-transform: uppercase;
  font-weight: bold;
}

.profitContainer {
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  color: #697578;
  font-weight: 400;
}

.profitMsg {
  color: #ef4639;
}

.totalPriceContainer {
  flex-direction: column;
  .totalPrice {
    font-size: 30px;
    line-height: 30px;
    color: #212529;
    font-weight: 500;
  }
  .interval {
    font-size: 12px;
    color: #697578;
  }
}

.chooseContainer {
  flex-direction: column;
  button {
    width: 94px;
    height: 37px;
    background: #ef4639;
    border-radius: 24px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 1200px) {
  .planCardContainer {
    width: 30%;
  }
}
